import React, {useEffect, useState} from "react";
import Comment from "./Comment";
import axios from 'axios';

const Comments = (props: any) => {

    type Comment = {
        id: string,
        upvotes?: number,
        downvotes?: number,
        body?: string
    }

    const [comments, setComments] = useState(Array<Comment>())
    const [isLoading, setLoading] = useState(false)
    // const [sort, setSort] = useState('Highest Rating')

    // const sortOptions = [
    //     { name: 'Highest Rating', href: '#', current: sort === 'Highest Rating' },
    //     { name: 'Newest', href: '#', current: sort === 'Newest' },
    //     { name: 'Oldest', href: '#', current: sort === 'Oldest' },
    // ]

    // function classNames(...classes: any) {
    //     return classes.filter(Boolean).join(' ')
    // }

    useEffect(() => {

        if (props.type !== 'comment' && props.type !== 'summary') {
            return;
        }

        const loadComments = async () => {
            if (! props.page?.id) {
                console.log('Missing page');
                return;
            }

            if (! props.type) {
                console.log('Missing comment type');
                return;
            }

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/api/pages/' + props.page.id + '/comments?type=' + props.type, {
            }).then(response => {
                console.log(response);
                setComments(response.data ?? []);
            });

            setLoading(false)
        }

        loadComments();

    }, [ props.forceReload, props.page.id, props.type ])

    if (isLoading) return <p>Loading...</p>

    if (comments && comments.length > 0) {
        return (
            <>
                {/*<div className="col-start-1 row-start-1 py-4 mt-5">*/}
                {/*    <div className="flex justify-start max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">*/}
                {/*        <Menu as="div" className="relative inline-block">*/}
                {/*            <div className="flex">*/}
                {/*                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 hover:dark:text-gray-200">*/}
                {/*                    Sort*/}
                {/*                    <ChevronDownIcon*/}
                {/*                        className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"*/}
                {/*                        aria-hidden="true"*/}
                {/*                    />*/}
                {/*                </Menu.Button>*/}
                {/*            </div>*/}

                {/*            <Transition*/}
                {/*                as={Fragment}*/}
                {/*                enter="transition ease-out duration-100"*/}
                {/*                enterFrom="transform opacity-0 scale-95"*/}
                {/*                enterTo="transform opacity-100 scale-100"*/}
                {/*                leave="transition ease-in duration-75"*/}
                {/*                leaveFrom="transform opacity-100 scale-100"*/}
                {/*                leaveTo="transform opacity-0 scale-95"*/}
                {/*            >*/}
                {/*                <Menu.Items className="origin-top-right absolute left mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">*/}
                {/*                    <div className="py-1">*/}
                {/*                        {sortOptions.map((option) => (*/}
                {/*                            <Menu.Item key={option.name}>*/}
                {/*                                {({ active }) => (*/}
                {/*                                    <a*/}
                {/*                                        // onclick={setSort(option.name)}*/}
                {/*                                        className={classNames(*/}
                {/*                                            option.current ? 'font-medium text-gray-900' : 'text-gray-500',*/}
                {/*                                            active ? 'bg-gray-100' : '',*/}
                {/*                                            'block px-4 py-2 text-sm'*/}
                {/*                                        )}*/}
                {/*                                    >*/}
                {/*                                        {option.name}*/}
                {/*                                    </a>*/}
                {/*                                )}*/}
                {/*                            </Menu.Item>*/}
                {/*                        ))}*/}
                {/*                    </div>*/}
                {/*                </Menu.Items>*/}
                {/*            </Transition>*/}
                {/*        </Menu>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {comments.map((comment: Comment) => (
                    <Comment key={comment.id} comment={comment}/>
                ))}
            </>
        )
    } else {
        return (
            <div className={"mt-10 text-center"}>
                No { props.type === 'summary' ? 'summaries' : 'comments' } yet.
                <div className={"text-4xl mt-5 filter grayscale animate-pulse"}>
                    😞
                </div>
            </div>
        )
    }
}

export default Comments

