import React, {useContext, useEffect, useState} from 'react';
import { updateProfile } from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import axios, {AxiosError} from 'axios';
import "./Profile.css";
import {AppContext} from "./AppContext";

export default function Profile() {

    useEffect(() => {
        document.title = "My Profile - Sum It Up"
    }, []);

    const state = useContext(AppContext)
    // const [user, loading, error] = useAuthState(auth);
    const [user] = useAuthState(auth);

    // if (! user) {
    //     return ('Not logged in')
    // }

    // const [country, setCountry] = useState('US');
    const [displayName, setDisplayName] = useState(user?.displayName);
    // const [publicProfile, setPublicProfile] = useState(''); // @todo need to get document from Firestore fist


    useEffect(() => {

        const loadProfile = () => {
            if (! user) {
                return;
            }

            if (! state.firebaseJWT) {
                return;
            }

            axios.post(process.env.REACT_APP_API_URL + '/api/verify-token', {
                token: state.firebaseJWT
                // headers: {
                //     // "Authorization": `Bearer ${theLoginToken}`
                //     "Authorization": `Bearer ${idToken}`
                // }
            }).then(response => {
                console.log(response);
                setDisplayName(response.data.user.displayName)
            })
                .catch((reason: AxiosError) => {
                    if (reason.response!.status === 401) {
                        localStorage.removeItem("firebaseJWT");
                        state.setFirebaseJWT("");
                    }
                });

            console.log('loadProfile: DONE')
        }

        loadProfile();
    }, [ user, state ]);


    const saveProfile = (e: any) => {
        e.preventDefault()

        if (! user) {
            return;
        }

        if (! state.firebaseJWT) {
            return;
        }

        updateProfile(user, {
            displayName: displayName //, photoURL: "https://example.com/jane-q-user/profile.jpg"
        }).then(() => {

            axios.post(process.env.REACT_APP_API_URL + '/api/myself', {
                token: state.firebaseJWT,
                displayName: displayName
            }).then(response => {
                console.log(response);
                //alert(JSON.stringify(response.data));
            });

        }).catch((error: any) => {
            // An error occurred
            // ...
            //alert(JSON.stringify(error))
        });

    }

    return (
        <>

            <main className="flex flex-col items-center justify-center w-full flex-1 px-20 text-center">
                <h1 className="text-5xl font-bold text-sky-600 dark:text-sky-400 mb-16 mt-16">
                    My Profile
                </h1>
            </main>

            {!user &&
                <>
                    <div className={"text-center"}>
                        Please login to update your profile.
                    </div>
                </>
            }

            {user &&

                <div className={"max-w-7xl mx-auto sm:px-1 md:px-10"}>
                    <div>
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50">Profile</h3>
                                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
                                        This information will be displayed publicly so be careful what you share.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-2">
                                <form action="#" method="POST">
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="px-4 py-5 bg-white dark:bg-slate-900 space-y-6 sm:p-6">

                                            <div className="sm:col-span-4">
                                                <label htmlFor="display_name"
                                                       className="block text-sm font-medium text-gray-700 dark:text-slate-400">
                                                    Display name
                                                </label>
                                                <div className="mt-1">
                                                    <input id="display_name" name="display_name" type="text"
                                                           onChange={(e) => setDisplayName(e.target.value)}
                                                           value={displayName ?? ''}
                                                           className="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-slate-800 p-2"/>
                                                </div>
                                            </div>


                                            {/*<label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-slate-400">*/}
                                            {/*    Country*/}
                                            {/*</label>*/}
                                            {/*<ReactFlagsSelect*/}
                                            {/*    selected={country}*/}
                                            {/*    onSelect={code => setCountry(code)}*/}
                                            {/*    searchable*/}
                                            {/*    className={"country-select"}*/}
                                            {/*/>*/}

                                            {/*<div>*/}
                                            {/*    <label htmlFor="about" className="block text-sm font-medium text-gray-700 dark:text-slate-400">*/}
                                            {/*        About*/}
                                            {/*    </label>*/}
                                            {/*    <div className="mt-1">*/}
                                            {/*      <textarea*/}
                                            {/*          id="about"*/}
                                            {/*          name="about"*/}
                                            {/*          rows={10}*/}
                                            {/*          className="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-slate-800"*/}
                                            {/*          placeholder="(Optional) Write about yourself..."*/}
                                            {/*          defaultValue={''}*/}
                                            {/*      />*/}
                                            {/*    </div>*/}
                                            {/*    <p className="mt-2 text-sm text-gray-500">*/}
                                            {/*        This will be visible to anyone who views your profile, including search engines.*/}
                                            {/*    </p>*/}
                                            {/*</div>*/}

                                            {/*<div className="col-span-6 sm:col-span-3">*/}
                                            {/*    <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-slate-400">*/}
                                            {/*        Country*/}
                                            {/*    </label>*/}
                                            {/*    <select*/}
                                            {/*        id="country"*/}
                                            {/*        name="country"*/}
                                            {/*        autoComplete="country-name"*/}
                                            {/*        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm dark:bg-slate-800"*/}
                                            {/*    >*/}
                                            {/*        <option>United States</option>*/}
                                            {/*        <option>Canada</option>*/}
                                            {/*        <option>Mexico</option>*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}

                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 dark:bg-slate-900 text-right sm:px-6">
                                            <button
                                                type="submit"
                                                onClick={saveProfile}
                                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/*<div className="hidden sm:block" aria-hidden="true">*/}
                    {/*    <div className="py-5">*/}
                    {/*        <div className="border-t border-gray-200 dark:border-slate-600" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="mt-10 sm:mt-0 mb-20">*/}
                    {/*    <div className="md:grid md:grid-cols-3 md:gap-6">*/}
                    {/*        <div className="md:col-span-1">*/}
                    {/*            <div className="px-4">*/}
                    {/*                <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50">Delete account</h3>*/}
                    {/*                <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">Destroy your account and all of your data.</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="mt-5 md:mt-0 md:col-span-2">*/}
                    {/*            <form action="#" method="POST">*/}
                    {/*                <div className="shadow overflow-hidden sm:rounded-md">*/}
                    {/*                    <div className="px-4 py-5 bg-white dark:bg-slate-800 space-y-6 sm:p-6">*/}

                    {/*                        <div>*/}
                    {/*                            By pressing the button below, your <b>Sum It Up</b> account and all of*/}
                    {/*                            your data will be irreversibly destroyed. This includes, but is not*/}
                    {/*                            limited to, your posts, comments, summaries, and upvotes.*/}
                    {/*                        </div>*/}

                    {/*                        <button*/}
                    {/*                            type="button"*/}
                    {/*                            className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"*/}
                    {/*                        >*/}
                    {/*                            <TrashIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />*/}
                    {/*                            Delete My Account*/}
                    {/*                        </button>*/}

                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </form>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                </div>

            }

            {/*User = { JSON.stringify(user) }*/}

        </>
    )
}
