import React, { useEffect } from "react";
import "./Login.css";

function Homepage() {

    useEffect(() => {
        document.title = "Sum It Up"
    }, []);

    return (
        <div className="flex flex-col items-center justify-center zmin-h-screen py-2 mt-20 mb-20 overflow-x-hidden">

            <main className="flex flex-col items-center justify-center w-full flex-1 px-5 md:px-10 lg:px-10 ztext-center">
                <h1 className="text-5xl lg:text-7xl font-extrabold sum-it-up-gradient">
                    Sum It Up
                </h1>

                {/*<LoginButton></LoginButton>*/}

                {/*<div className={"mt-3 text-gray-500"}>*/}
                {/*  Ain&apos;t nobody got time to read that article*/}
                {/*</div>*/}

                <p className="mt-12 text-lg sm:text-xl md:text-2xl">
                    {/*<span className={"text-xl bg-sky-700 text-white align-text-bottom w-10 py-1 px-1 -mb-1 rounded-full inline-block mr-3"}>1</span>*/}
                    Drag this to your bookmarks toolbar {' '}
                    {/*<svg xmlns="http://www.w3.org/2000/svg" className={"inline-block"} width="120" height="35"><g fill="none" stroke="#000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"><path d="M2.604 11.713C.493 23.5 14.752 38.096 25.349 28.298c4.057-3.752 6.124-12.578 11.736-14.328 5.07-1.582 10.508 3.462 14.03 6.42 3.312 2.783 6.481 7.043 11.264 6.274 5.658-.909 7.922-7.63 12.645-9.502 3.914-1.551 1.654.373 5.418 2.064 8.492 3.815 26.078-8.708 32.912-12.264"/><path d="M113.166 14.662c.126-4.386 3.5-7.089 4.44-11.18-3.141.627-9.566 1.613-12.685.331"/></g></svg>*/}
                    <svg xmlns="http://www.w3.org/2000/svg" className="hidden md:inline-block" width="95" height="20"><g fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeMiterlimit="10"><path d="M3.471 11.53c1.522 1.408 3.577 1.145 5.935 1.174 9.331.121 18.768-.963 28.067-1.583 15.529-1.034 32.082-3.414 47.576-.777"/><path strokeLinejoin="round" d="M81.662 16.798c2.522-3.59 6.828-3.986 9.867-6.88-2.967-1.208-8.871-3.928-10.767-6.716"/></g></svg>

                    {process.env.NODE_ENV === 'production' &&
                        // eslint-disable-next-line
                        <a href="javascript: (function () { var jsCode = document.createElement('script'); jsCode.setAttribute('src', 'https://sumitup.org/sumitup-bookmarklet.js'); document.body.appendChild(jsCode); }());"
                           className="text-3xl font-bold shadow-md ml-2 zinline-flex px-10 py-3 border border-transparent rounded-md text-white bg-red-500 hover:bg-red-600 block sm:inline-flex text-center">
                            Sum It Up
                        </a>
                    }

                    {process.env.NODE_ENV !== 'production' &&
                        // eslint-disable-next-line
                        <a href="javascript: (function () { var jsCode = document.createElement('script'); jsCode.setAttribute('src', 'https://sumitup.test:3003/sumitup-bookmarklet-dev.js'); document.body.appendChild(jsCode); }());"
                           className="text-3xl font-bold shadow-md ml-2 zinline-flex px-10 py-3 border border-transparent rounded-md text-white bg-red-500 hover:bg-red-600 block sm:inline-flex text-center">
                            Sum It Up
                        </a>
                    }
                </p>

                <div className={"mt-8 text-gray-500 dark:text-gray-400 text-xl"}>
                    {/*<span className={"text-xl bg-sky-700 text-white align-text-bottom w-10 py-1 px-1 -mb-1 rounded-full inline-block mr-3"}>2</span>*/}
                    Then go to any video or news article and click your <b>Sum It Up</b> bookmark.
                </div>

                {/*<div className={"mt-8 text-gray-500 dark:text-gray-400 text-xl"}>*/}
                {/*    <span className={"text-xl bg-sky-700 text-white align-text-bottom w-10 py-1 px-1 -mb-1 rounded-full inline-block mr-3"}>3</span>*/}
                {/*    Save valuable time by reading a summary of the article.*/}
                {/*</div>*/}

                {/*</main>*/}




                {/*<main className="lg:relative w-full">*/}
                {/*    <div className="mx-auto zmax-w-7xl zw-full pt-16 pb-20 text-center lg:py-48 lg:text-left">*/}
                {/*        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">*/}
                {/*            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">*/}
                {/*                <span className="block xl:inline">Seriously,</span>{' '}*/}
                {/*                <span className="block text-indigo-600 xl:inline">just get to the point</span>*/}
                {/*            </h1>*/}
                {/*            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">*/}
                {/*                People write articles and tend to ramble on and on, often because they&apos;re paid by the word. When you encounter a*/}
                {/*                webpage that&apos;s needlessly long and you don&apos;t have time to read it all,*/}
                {/*                click the <b>Sum It Up</b> bookmarklet in your toolbar to*/}
                {/*                quickly read a human-written summary of the article (if one exists). It&apos;s*/}
                {/*                like having a <b>TL;DR</b> for every webpage.*/}
                {/*                <br/><br/>*/}
                {/*                If a summary doesn&apos;t exist yet (or if you think you can summarize it*/}
                {/*                better) you can take one for the team by reading the article and writing*/}
                {/*                the summary yourself. When others encounter that article or webpage in the*/}
                {/*                future, they can read your summary instead.*/}

                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">*/}
                {/*        <img className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"*/}
                {/*             src="/sum-it-up-example.png"*/}
                {/*             alt="Example of Sum It Up in action on a lengthy news article"/>*/}
                {/*    </div>*/}
                {/*</main>*/}





                <div className="relative pt-16 overflow-hidden sm:pt-24 lg:pt-32">
                    <div className="mx-auto max-w-md px-1 sm:px-4 ztext-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                        <div>
                            <p className="mt-2 text-3xl font-extrabold text-gray-900 dark:text-gray-100 tracking-tight sm:text-4xl text-center">
                                TL;DR for articles, videos, and more
                            </p>
                            <p className="mt-10 max-w-prose mx-auto text-md sm:text-lg md:text-xl text-gray-500 dark:text-gray-400">

                                {/*People tend to ramble on and on when they write articles or create videos,*/}
                                {/*often because they&apos;re paid by the word or incentivized to keep you there.*/}
                                When you encounter a webpage or video that&apos;s needlessly long and you
                                don&apos;t have time to read or watch it all (or it&apos;s in a different language),
                                click the <b>Sum It Up</b> bookmarklet in your toolbar to
                                quickly read human-written summaries of the article or video.
                                It&apos;s like having a <b title={"Too Long; Didn't Read"} className={"underline decoration-dotted cursor-help"}>TL;DR</b> for everything on the Internet.
                                <br/><br/>

                                If a summary doesn&apos;t exist yet (or if you think you can summarize it
                                better), you can take one for the team by reading the article and writing
                                the summary yourself. When others encounter that article or webpage in the
                                future, they can read your summary instead. If it&apos;s good enough,
                                they&apos;ll upvote it 👍 and move it to the top.

                            </p>
                        </div>
                        {/*<div className="mt-12 z-mb-10 zsm:-mb-24 zlg:-mb-80">*/}
                        {/*    <img className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"*/}
                        {/*         src="/sum-it-up-example.png"*/}
                        {/*         alt="Example of Sum It Up in action on a lengthy news article"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </main>



            <main className="flex flex-col items-center justify-center w-full flex-1 px-2 md:px-10 lg:px-10 text-center bg-slate-200 dark:bg-sky-900 dark:bg-opacity-40 mt-20 py-20">

                <div className="relative px-2 md:px-10 overflow-hidden zsm:pt-24 lg:px-16">
                    <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                        <div>
                            <p className="mt-2 text-3xl font-extrabold text-gray-900 dark:text-gray-100 tracking-tight sm:text-4xl">
                                Discussion without censorship.
                            </p>
                            <p className="mt-10 max-w-prose mx-auto text-xl text-gray-500 dark:text-gray-400">

                                Click the <b>Comments</b> tab to discuss the article or video in an
                                environment outside of the website owner's control.
                                {/*<sup>*</sup>*/}

                              {/*  <span className={"mt-8 text-xs block"}>*/}
                              {/*    <sup>*</sup>This excludes spam, illegal content, and anything not protected by the*/}
                              {/*    First Amendment in the United States.*/}
                              {/*</span>*/}

                            </p>
                        </div>
                    </div>
                </div>
            </main>


            <main className="flex flex-col items-center justify-center w-full flex-1 px-5 md:px-10 lg:px-10 text-center">

                <div className="relative zbg-white py-16 sm:py-8 lg:py-16 mt-0">
                    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="mt-12">
                            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">

                                <div className="pt-6">
                                    <div className="flow-root rounded-lg bg-white dark:bg-slate-800 px-6 pb-8 shadow">
                                        <div className="-mt-6">
                                            <div>
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-violet-500 p-3 shadow-lg">
                                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                                                     fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                                              </svg>
                                            </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900 dark:text-gray-100">
                                                Anonymous if not posting or voting
                                            </h3>
                                            <p className="mt-5 text-base text-gray-500 dark:text-gray-400">
                                                If you only want to read summaries and comments, you don&apos;t
                                                need an account.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-6">
                                    <div className="flow-root rounded-lg bg-white dark:bg-slate-800 px-6 pb-8 shadow">
                                        <div className="-mt-6">
                                            <div>
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-sky-500 p-3 shadow-lg">
                                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                                                     fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                                              </svg>
                                            </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900 dark:text-gray-100">
                                                Free, quick registration
                                            </h3>
                                            <p className="mt-5 text-base text-gray-500 dark:text-gray-400">
                                                We only care about your email address and your unique
                                                Internet nickname.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-6">
                                    <div className="flow-root rounded-lg bg-white dark:bg-slate-800 px-6 pb-8 shadow">
                                        <div className="-mt-6">
                                            <div>
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-lime-500 p-3 shadow-lg">
                                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                                                     fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                                              </svg>
                                            </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900 dark:text-gray-100">
                                                {/*Circle of friends*/}
                                                Multiple languages
                                            </h3>
                                            <p className="mt-5 text-base text-gray-500 dark:text-gray-400">
                                                {/*Allow your posts to be viewed by anyone in the world,*/}
                                                {/*or just your friends.*/}
                                                Post and read comments in any language.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-6">
                                    <div className="flow-root rounded-lg bg-white dark:bg-slate-800 px-6 pb-8 shadow">
                                        <div className="-mt-6">
                                            <div>
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-rose-500 p-3 shadow-lg">
                                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                                                     fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                                              </svg>
                                            </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900 dark:text-gray-100">
                                                Dark mode
                                            </h3>
                                            <p className="mt-5 text-base text-gray-500 dark:text-gray-400">
                                                Toggle between light mode and dark mode by pressing a button.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-6">
                                    <div className="flow-root rounded-lg bg-white dark:bg-slate-800 px-6 pb-8 shadow">
                                        <div className="-mt-6">
                                            <div>
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-amber-500 p-3 shadow-lg">
                                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                                                     fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                                              </svg>
                                            </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900 dark:text-gray-100">
                                                No censorship
                                            </h3>
                                            <p className="mt-5 text-base text-gray-500 dark:text-gray-400">
                                                Spam and illegal content are removed. Freedom of speech is
                                                protected.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-6">
                                    <div className="flow-root rounded-lg bg-white dark:bg-slate-800 px-6 pb-8 shadow">
                                        <div className="-mt-6">
                                            <div>
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-teal-500 p-3 shadow-lg">
                                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                                                     fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                                              </svg>
                                            </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900 dark:text-gray-100">
                                                Time saver
                                            </h3>
                                            <p className="mt-5 text-base text-gray-500 dark:text-gray-400">
                                                Read only the important information. Skip over the author&apos;s clever banter.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                {/*                <div className="py-12 bg-white mt-10">*/}

                {/*    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">*/}
                {/*        <div className="mt-10">*/}
                {/*            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">*/}
                {/*                <div className="relative text-left">*/}
                {/*                    <dt>*/}
                {/*                        <div*/}
                {/*                            className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">*/}
                {/*                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"*/}
                {/*                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">*/}
                {/*                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
                {/*                                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>*/}
                {/*                            </svg>*/}
                {/*                        </div>*/}
                {/*                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">*/}
                {/*                            Anonymous viewing*/}
                {/*                        </p>*/}
                {/*                    </dt>*/}
                {/*                    <dd className="mt-2 ml-16 text-base text-gray-500">*/}
                {/*                        If you just want to view summaries and comments, you don&apos;t need*/}
                {/*                        to create an account.*/}
                {/*                    </dd>*/}
                {/*                </div>*/}
                {/*            </dl>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*</div>*/}



            </main>

        </div>

    )
}

export default Homepage;
