import React, {useEffect, useState} from "react";
import axios from "axios";
import PostMessageBox from "./components/PostMessageBox";
import Comments from "./components/Comments";
import {useParams} from "react-router-dom";
import {analytics} from "./firebase";
import { logEvent } from "firebase/analytics";


export default function Page(props: any) {

    const [activeTab, setActiveTab] = useState('Summaries');
    const [forceReload, setForceReload] = useState(0);

    let initialPageData:any = {}
    const [page, setPage] = useState(initialPageData);

    useEffect(() => {
        document.title = "Sum It Up: " + page.title
    }, [page]);

    const [tabs, setTabs] = useState([
        { name: 'Summaries', href: '#', count: 0 },
        { name: 'Comments', href: '#', count: 0 },
    ]);

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const selectTab = (tab: string) => (e: any) => {
        setActiveTab(tab)
    }

    const parentCallback = () => {
        //alert('done');
        setForceReload(Math.random() * 1000);
    }

    const [isLoading, setLoading] = useState(false)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const url = params.get('url');
    const title = params.get('title');
    let { id } = useParams();

    useEffect(() => {
        async function fetchData() {

            // If  ?id={PAGE_ID}  is provided, assume it's the page key in Firestore
            //if (router.query.id) {

            console.log(url);
            console.log(id);

            if (id) {

                logEvent(analytics, 'view_page_by_id', {
                    page_id: id
                });

                axios.get(process.env.REACT_APP_API_URL + '/api/pages/' + id, {
                }).then(response => {
                    console.log(response);
                    //setComments(response.data ?? []);
                    //setUserDB(response.data);
                    setPage(response.data);

                    setTabs([
                        { name: 'Summaries', href: '#', count: response.data.num_summaries },
                        { name: 'Comments', href: '#', count: response.data.num_comments },
                    ]);

                    return;
                    //setDisplayName(response.data.user.displayName)
                });

            // const docRef = doc(db, "pages", router.query.id.toString());
                // const docSnap = await getDoc(docRef);
                //
                //
                // if (docSnap.exists()) {
                //
                //     let thePage = docSnap.data();
                //     thePage.id = router.query.id;
                //
                //     console.log(thePage)
                //
                //     setPage(thePage)
                //     setLoading(false);
                //     return;
                // }

                //alert('unable to find page based on ID');
            }

            // Otherwise, use the  ?url={URL}  to find or create the Firestore page ID
            //if (!router.query.url) {
            if (!url) {
                return;
            }

            setLoading(true)

            let urlWithoutQueryString = url.toString().split('?')[0];

            axios.post(process.env.REACT_APP_API_URL + '/api/pages', {
                url: url,
                url_without_query_string: urlWithoutQueryString,
                title: title,
                //token: idToken
                // headers: {
                //     // "Authorization": `Bearer ${theLoginToken}`
                //     "Authorization": `Bearer ${idToken}`
                // }
            }).then(response => {
                console.log(response);
                setPage(response.data);

                setTabs([
                    { name: 'Summaries', href: '#', count: response.data.num_summaries },
                    { name: 'Comments', href: '#', count: response.data.num_comments },
                ]);

                //setComments(response.data ?? []);
                //setDisplayName(response.data.user.displayName)
            });

            setLoading(false)
        }
        fetchData();
    }, [ id, url, title ]);


    return (

        <>
            {/*<NavBarInBookmarklet/>*/}

            <div className="max-w-7xl w-full mx-auto px-2 sm:px-4 lg:px-8">
                <main className="flex flex-col w-full flex-1 pt-10 px-4">
                    <div className="items-center justify-center text-center">
                        <h1 className="text-4xl font-extrabold sum-it-up-gradient mb-8 inline-block">
                            Sum It Up
                        </h1>
                    </div>

                    {isLoading &&
                        <>
                            <div className={"m-auto mt-10 text-center"}>
                                <svg className="animate-spin -ml-1 mr-3 h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg"
                                     fill="none"
                                     viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            strokeWidth="4"/>
                                    <path className="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                </svg>
                            </div>
                            <div className={"text-center mt-10"}>
                                Searching
                            </div>
                        </>
                    }

                    {!isLoading && !page.id &&
                        <>Unable to create page</>
                    }

                    {!isLoading && page.id &&
                        <>
                            <div className={"mb-4"}>
                                <div className="ml-0 mt-2 text-gray-700 dark:text-gray-300">{ page.title }</div>

                                <a className="block text-xs whitespace-nowrap w-full inline-block overflow-hidden text-gray-600 dark:text-gray-400" target="_top" href={page.url} rel="noreferrer">
                                    { page.url }
                                </a>
                            </div>

                            <div>
                                <div className="block">
                                    <div className="border-b border-gray-300 dark:border-gray-500 mb-2">
                                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                            {tabs.map((tab) => (
                                                <button
                                                    key={tab.name}
                                                    onClick={selectTab(tab.name)}
                                                    className={classNames(
                                                        tab.name === activeTab
                                                            ? 'border-indigo-500 text-indigo-600 dark:border-sky-400 dark:text-sky-300'
                                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200    dark:text-gray-300 dark:hover:text-gray-50 dark:hover:border-gray-100',
                                                        'whitespace-nowrap flex py-3 px-1 border-b-2 font-medium text-sm'
                                                    )}
                                                    aria-current={tab.name === activeTab ? 'page' : undefined}
                                                >
                                                    {tab.name}
                                                    {tab.count ? (
                                                        <span
                                                            className={classNames(
                                                                tab.name === activeTab ? 'bg-indigo-100 text-indigo-600 dark:bg-indigo-900 dark:text-indigo-200' : 'bg-gray-100 text-gray-900 dark:bg-slate-700 dark:text-slate-300',
                                                                'ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                            )}
                                                        >
                                                        {tab.count}
                                                      </span>
                                                    ) : null}
                                                </button>
                                            ))}
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            {activeTab === 'Summaries' &&
                                <>
                                    <PostMessageBox page={page} type={"summary"} onSuccess={parentCallback}/>
                                    <Comments page={page} type={"summary"} forceReload={forceReload}/>
                                </>
                            }

                            {activeTab === 'Comments' &&
                                <>
                                    <PostMessageBox page={page} type={"comment"} onSuccess={parentCallback}/>
                                    <Comments page={page} type={"comment"} forceReload={forceReload}/>
                                </>
                            }
                        </>
                    }

                </main>
            </div>

        </>
    )
}
