import React, { useState } from "react";

// set the defaults
export const AppContext = React.createContext({
    firebaseJWT: "",
    setFirebaseJWT: (firebaseJWT: string) => {}
});

export const AppContextProvider = (props: any) => {

    const setFirebaseJWT = (firebaseJWT: string) => {
        setState({...state, firebaseJWT: firebaseJWT})
    }

    const initState = {
        firebaseJWT: "",
        setFirebaseJWT: setFirebaseJWT
    }

    const [state, setState] = useState(initState)

    return (
        <AppContext.Provider value={state}>
            {props.children}
        </AppContext.Provider>
    )
}