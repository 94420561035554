import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './AppContext'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
// "Strict mode can’t automatically detect side effects for you, but it can help
// you spot them by making them a little more deterministic. This is done by
// intentionally double-invoking the following functions..."

root.render(
  // <React.StrictMode>
    <AppContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
