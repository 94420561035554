import React, {useEffect} from "react";
import { Email } from "react-obfuscate-email";

export default function Contact() {

    useEffect(() => {
        document.title = "Contact Us - Sum It Up"
    }, []);

    return (
        <>
            <main className="w-full flex-1 px-5 md:px-20 mb-20">
                <h1 className="text-5xl font-bold text-sky-600 dark:text-sky-400 mb-16 mt-16 text-center">
                    Contact Us
                </h1>

                <div className="mt-10 max-w-prose mx-auto text-md sm:text-lg md:text-xl text-gray-600 dark:text-gray-400 text-center">

                    <Email email="support@sumitup.org"
                           className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    >Email us</Email>

                </div>

            </main>
        </>
    )
}
