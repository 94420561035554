import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { getFirestore, addDoc, collection } from "firebase/firestore";

// import {
//     GoogleAuthProvider,
//     getAuth,
//     signInWithPopup,
//     signInWithEmailAndPassword,
//     createUserWithEmailAndPassword,
//     sendPasswordResetEmail,
//     signOut,
// } from "firebase/compat/auth";
// import {
//     getFirestore,
//     query,
//     getDocs,
//     collection,
//     where,
//     addDoc,
// } from "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
// const googleProvider = new GoogleAuthProvider();
// const signInWithGoogle = async () => {
//     try {
//         const res = await signInWithPopup(auth, googleProvider);
//         const user = res.user;
//         const q = query(collection(db, "users"), where("uid", "==", user.uid));
//         const docs = await getDocs(q);
//         if (docs.docs.length === 0) {
//             await addDoc(collection(db, "users"), {
//                 uid: user.uid,
//                 name: user.displayName,
//                 authProvider: "google",
//                 email: user.email,
//             });
//         }
//     } catch (err) {
//         console.error(err);
//         alert(err.message);
//     }
// };
const logInWithEmailAndPassword = async (email: string, password: string) => {

    // signInWithEmailAndPassword(auth, email, password)
    //     .then((userCredential) => {
    //         // setEmail('')
    //         // setPassword('')
    //         // setSignInError('')
    //         // setSignUpError('')
    //         // setOpen(false)
    //     })
    //     .catch((error) => {
    //         var publicErrorMessage = error.code;
    //         if (error.code === 'auth/wrong-password') {
    //             publicErrorMessage = "Incorrect password.";
    //         } else if (error.code === 'auth/user-not-found') {
    //             publicErrorMessage = "Account not found for that email address.";
    //         } else if (error.code === 'auth/user-disabled') {
    //             publicErrorMessage = "Account disabled.";
    //         } else if (error.code === 'auth/invalid-email') {
    //             publicErrorMessage = "Invalid email address.";
    //         } else if (error.code === 'auth/internal-error') {
    //             publicErrorMessage = "We encountered a problem. Please try again.";
    //         }
    //
    //         setSignInError(publicErrorMessage)
    //     });
};
const registerWithEmailAndPassword = async (name : string, email : string, password : string) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        });
    } catch (err: any) {
        console.error(err);
        alert(err.message);
    }
};
const sendPasswordReset = async (email : string) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err: any) {
        console.error(err);
        alert(err.message);
    }
};
const logout = () => {
    signOut(auth);
};
export {
    auth,
    db,
    analytics,
    // signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
};