import React, {useEffect} from "react";
import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Homepage from './Homepage';
import About from './About';
import MyPosts from './MyPosts';
import Profile from './Profile';
import Settings from './Settings';
import Page from './Page';
import User from './User';
import Privacy from './Privacy';
import Terms from './Terms';
import Contact from './Contact';
import Recent from './Recent';

function App() {

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0,0);
    }, [location]);

    useEffect(() => {
        document.body.className = 'bg-gray-100 dark:bg-slate-900 text-slate-700 dark:text-slate-300';
    });

    return (
        <div className="App">
            <NavBar/>
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="about" element={<About />} />
                <Route path="profile" element={<Profile />} />
                <Route path="settings" element={<Settings />} />
                <Route path="my-posts" element={<MyPosts />} />
                <Route path="page" element={<Page />} />
                <Route path="page/:id" element={<Page />} />
                <Route path="user/:username" element={<User />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="contact" element={<Contact />} />
                <Route path="recent" element={<Recent />} />
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
