import { Link } from "react-router-dom";
import React, { useEffect, useState, useContext} from 'react'
import {Dialog, Disclosure, Menu} from '@headlessui/react'
import { XIcon} from '@heroicons/react/outline'
import useDarkMode from "../useDarkMode";
import { useAuthState } from "react-firebase-hooks/auth";
import axios, { AxiosError } from 'axios'
import {analytics, auth} from "../firebase";
import {signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { AppContext } from '../AppContext'
import {logEvent} from "firebase/analytics";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

function NavBar() {

    const [colorTheme, setTheme] = useDarkMode();

    // const [user, loading, error] = useAuthState(auth);
    const [user] = useAuthState(auth);

    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [name, setName] = useState('');
    const [signInError, setSignInError] = useState('');
    const [signUpError, setSignUpError] = useState('');

    // const navigate = useNavigate();

    const state = useContext(AppContext)

    useEffect(() => {
        const getOrSetToken = () => {

            if (!state.firebaseJWT || state.firebaseJWT.length === 0) {
                console.log("Missing or empty Firebase JWT. Fetching and saving to localstorage...")

                if (!auth) {
                    console.log("Missing 'auth'");
                    return;
                }

                // if (loading) {
                //     console.log("Issue: loading")
                //     return;
                // }
                //
                // if (error) {
                //     console.log("Issue: error")
                //     return;
                // }

                if (!auth.currentUser) {
                    console.log("Missing 'auth.currentUser'");
                    return;
                }

                // see if it's in localstorage
                if (localStorage.firebaseJWT && localStorage.firebaseJWT.length > 0) {
                    console.log("Found in local storage: " + localStorage.firebaseJWT)
                    state.setFirebaseJWT(localStorage.firebaseJWT)
                } else {
                    // Get from Firebase using their API
                    auth.currentUser.getIdToken(true).then(function (idToken) {
                        if (idToken) {
                            console.log("Found using Firebase API: " + idToken)
                            localStorage.setItem('firebaseJWT', idToken);
                            state.setFirebaseJWT(idToken)
                        }
                    });
                }
            }

            console.log("Your token is: " + state.firebaseJWT)

        }

        getOrSetToken()

        //return (
            // <button onClick={() => setLanguage("jp")}>
            //     Switch Language (Current: {language})
            // </button>
        //);
    }, [user, state]);


    // const get_user = async (theLoginToken: string) => {

        // console.log("===FROM get_user===")
        // console.log(theLoginToken);

        // if (theLoginToken && theLoginToken.length > 0){
        //     const headers = {
        //         "Authorization" : `Bearer ${ theLoginToken }`
        //     };
        //
        //     let result = await axios( {
        //         method: 'get',
        //         url: 'auth-user',
        //         baseURL: API_URL,
        //         data: JSON.stringify({}),
        //         headers: headers,
        //     } );
        //
        //     if (result.data) {
        //         setUser(result.data)
        //         //mutate(result.data)
        //         // mutateUser(result.data);
        //         localStorage.setItem('user', JSON.stringify(result.data));
        //         console.log("user is now...");
        //         console.log(result.data)
        //         console.log(user)
        //     } else {
        //         alert(JSON.stringify(result.data));
        //     }
        //
        //
        // } else {
        //     console.log("Login Token is empty");
        // }

    // }

    const signIn = async () => {

        localStorage.removeItem("firebaseJWT");
        state.setFirebaseJWT("");

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {

                logEvent(analytics, 'login', {
                    method: "Email"
                });

                setEmail('')
                setPassword('')
                setSignInError('')
                setSignUpError('')
                setOpen(false)
            })
            .catch((error) => {
                var publicErrorMessage = error.code;
                if (error.code === 'auth/wrong-password') {
                    publicErrorMessage = "Incorrect password.";
                } else if (error.code === 'auth/user-not-found') {
                    publicErrorMessage = "Account not found for that email address.";
                } else if (error.code === 'auth/user-disabled') {
                    publicErrorMessage = "Account disabled.";
                } else if (error.code === 'auth/invalid-email') {
                    publicErrorMessage = "Invalid email address.";
                } else if (error.code === 'auth/internal-error') {
                    publicErrorMessage = "We encountered a problem. Please try again.";
                }

                setSignInError(publicErrorMessage)
            });

    };

    const createAccount = async () => {

        localStorage.removeItem("firebaseJWT");
        state.setFirebaseJWT("");

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                
                logEvent(analytics, 'sign_up', {
                    method: "Email"
                });

                console.log(userCredential);

                try {
                    const headers = {
                        "Content-Type" : `multipart/form-data`,
                        "Accept": "application/json"
                    };

                    let data = new FormData();
                    data.append( 'email', email );
                    data.append( 'password', password );
                    // data.append( 'username', name );
                    data.append( 'firebase_uid', userCredential.user.uid);

                    // axios.get('/sanctum/csrf-cookie').then(response => {
                    //     // Login...
                    // });
                    // axios.defaults.withCredentials = true;

                    axios( {
                        method: 'post',
                        url: process.env.REACT_APP_API_URL + '/api/register',
                        data: data,
                        headers: headers,
                    })
                        .then(response => {
                        console.log(response);

                        setOpen2(false)
                        setEmail('')
                        setPassword('')
                        setSignInError('')
                        setSignUpError('')

                    })
                        .catch((reason: AxiosError) => {
                            if (reason.response!.status === 401) {
                                localStorage.removeItem("firebaseJWT");
                                state.setFirebaseJWT("");
                            }
                        });
                    ;

                } catch (e) {
                    alert(JSON.stringify(e));
                }
            })
            .catch((error: any) => {
                var publicErrorMessage = error.code;
                if (error.code === 'auth/email-already-in-use') {
                    publicErrorMessage = ''; // "Email address already in use.";
                    setOpen2(false)
                    setOpen(true)
                    setSignInError(publicErrorMessage)
                    signIn();
                }

                setSignUpError(publicErrorMessage)
            });
    };

    const handleSignIn = (e: any) => {
        e.preventDefault()
        signIn()
    }
    const handleSignUp = (e: any) => {
        e.preventDefault()
        createAccount()
    }

    const handleSignout = (e: any) => {
        e.preventDefault()
        auth.signOut()
        localStorage.removeItem("firebaseJWT");
        state.setFirebaseJWT("");
    }

    return (
        <>
            <Disclosure as="nav" className="dark:bg-gray-800 bg-white shadow dark:shadow-none sticky top-0 z-40">
                {({ open }) => (
                    <>
                        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                            <div className="relative flex items-center justify-between h-16">
                                <div className="flex items-center px-2 lg:px-0">
                                    <div className="flex-shrink-0">
                                        {/*<img*/}
                                        {/*    className="block lg:hidden h-8 w-auto"*/}
                                        {/*    src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"*/}
                                        {/*    alt="Workflow"*/}
                                        {/*/>*/}
                                        {/*<img*/}
                                        {/*    className="hidden lg:block h-8 w-auto"*/}
                                        {/*    src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"*/}
                                        {/*    alt="Workflow"*/}
                                        {/*/>*/}

                                        <button
                                            type="button"
                                            className="mr-2 flex-shrink-0 dark:bg-gray-800 p-1 rounded-full dark:text-gray-400 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                        >
                                            {colorTheme === "light" ? (
                                                <svg
                                                    onClick={() => setTheme("light")}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6 text-yellow-500"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    onClick={() => setTheme("dark")}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6 text-blue-500"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                                                    />
                                                </svg>
                                            )}
                                        </button>

                                    </div>
                                    <div className="hidden md:block md:ml-6">
                                        <div className="flex space-x-4">
                                            {/* Current: "dark:bg-gray-900 dark:text-white", Default: "dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white" */}
                                            <Link to="/"
                                                    // className="dark:bg-gray-900 dark:text-white px-3 py-2 rounded-md text-sm font-medium">
                                                    className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                                    Home
                                            </Link>
                                            {user &&
                                                <Link to="/my-posts"
                                                      className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                                    My Posts
                                                </Link>
                                            }
                                            <Link to="/recent"
                                                  className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                                Recent
                                            </Link>

                                            {/*<a*/}
                                            {/*    href="#"*/}
                                            {/*    className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium"*/}
                                            {/*>*/}
                                            {/*    Recent*/}
                                            {/*</a>*/}
                                            {/*<a*/}
                                            {/*    href="#"*/}
                                            {/*    className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium"*/}
                                            {/*>*/}
                                            {/*    About*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">*/}
                                {/*    <div className="max-w-lg w-full lg:max-w-xs">*/}
                                {/*        <label htmlFor="search" className="sr-only">*/}
                                {/*            Search*/}
                                {/*        </label>*/}
                                {/*        <div className="relative">*/}
                                {/*            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">*/}
                                {/*                <SearchIcon className="h-5 w-5 dark:text-gray-400" aria-hidden="true" />*/}
                                {/*            </div>*/}
                                {/*            <input*/}
                                {/*                id="search"*/}
                                {/*                name="search"*/}
                                {/*                className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-transparent rounded-md leading-5 bg-white dark:bg-gray-700 dark:text-gray-300 placeholder-gray-400 focus:outline-none focus:ring-white dark:focus:text-gray-100 sm:text-sm"*/}
                                {/*                placeholder="Search"*/}
                                {/*                type="search"*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="flex lg:hidden">
                                    {/* Mobile menu button */}
                                    {/*<Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">*/}
                                    {/*    <span className="sr-only">Open main menu</span>*/}
                                    {/*    {open ? (*/}
                                    {/*        <XIcon className="block h-6 w-6" aria-hidden="true" />*/}
                                    {/*    ) : (*/}
                                    {/*        <MenuIcon className="block h-6 w-6" aria-hidden="true" />*/}
                                    {/*    )}*/}
                                    {/*</Disclosure.Button>*/}

                                    {
                                        user &&
                                        <Menu as="div" className="ml-4 relative flex-shrink-0 mr-4">
                                            <Menu.Button className="dark:bg-gray-800 rounded-full flex text-sm dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                <span className="sr-only">Open user menu</span>
                                                {user.photoURL &&
                                                    <img
                                                        className="h-8 w-8 rounded-full border border-gray-300"
                                                        src={user.photoURL}
                                                        alt=""
                                                    />
                                                }

                                                {!user.photoURL &&
                                                    <svg className="h-8 w-8 rounded-full text-gray-300 border border-gray-300" fill="currentColor"
                                                         viewBox="0 0 24 24">
                                                        <path
                                                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                    </svg>
                                                }
                                            </Menu.Button>
                                            {/*<Transition*/}
                                            {/*    as={Fragment}*/}
                                            {/*    enter="transition ease-out duration-100"*/}
                                            {/*    enterFrom="transform opacity-0 scale-95"*/}
                                            {/*    enterTo="transform opacity-100 scale-100"*/}
                                            {/*    leave="transition ease-in duration-75"*/}
                                            {/*    leaveFrom="transform opacity-100 scale-100"*/}
                                            {/*    leaveTo="transform opacity-0 scale-95"*/}
                                            {/*>*/}
                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-60 rounded-md dark:border-gray-700 border-2 shadow-lg py-1 dark:pt-0 bg-white dark:bg-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none">

                                                <div className="flex items-center px-5 border-b-2 dark:border-gray-700">
                                                    <div className={"my-2"}>
                                                        <div
                                                            className="text-base font-medium text-black dark:text-white">{user.displayName ?? 'Anonymous'}</div>
                                                        {/*<div className="text-sm font-medium dark:text-gray-400">{user.email}</div>*/}
                                                        {/*<div className="text-sm font-medium text-gray-400 dark:text-gray-500">*/}
                                                        {/*    Member since 4/9/2022*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link to="/" target={"_blank"}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                                )}
                                                            >
                                                                Go Home
                                                        </Link>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link to="/profile"
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                                )}
                                                            >
                                                                My Profile
                                                        </Link>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link to="/settings"
                                                              className={classNames(
                                                                  active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                  'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                              )}
                                                        >
                                                            Settings
                                                        </Link>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link to="/my-posts"
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                                )}
                                                            >
                                                                My Posts
                                                        </Link>
                                                    )}
                                                </Menu.Item>

                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link to="/recent"
                                                              className={classNames(
                                                                  active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                  'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                              )}
                                                        >
                                                            Recent
                                                        </Link>
                                                    )}
                                                </Menu.Item>

                                                {/*<Menu.Item>*/}
                                                {/*    {({ active }) => (*/}
                                                {/*        <Link href={"/settings"}>*/}
                                                {/*            <a*/}
                                                {/*                className={classNames(*/}
                                                {/*                    active ? 'bg-gray-100 dark:bg-gray-800' : '',*/}
                                                {/*                    'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'*/}
                                                {/*                )}*/}
                                                {/*            >*/}
                                                {/*                Settings*/}
                                                {/*            </a>*/}
                                                {/*        </Link>*/}
                                                {/*    )}*/}
                                                {/*</Menu.Item>*/}
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link to="/api/auth/signout"
                                                                onClick={handleSignout}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                                )}
                                                            >
                                                                Sign out
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                            {/*</Transition>*/}
                                        </Menu>
                                    }

                                    {
                                        !user &&
                                        <>
                                            <button onClick={() => setOpen2(true)}
                                                    className="cursor-pointer m-5 inline-flex items-center px-4 py-2 border border-gray-300 dark:border-sky-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-transparent dark:text-sky-300 hover:bg-gray-50 dark:hover:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Sign up
                                            </button>

                                            <button onClick={() => setOpen(true)}
                                                    className="cursor-pointer m-5 inline-flex items-center px-4 py-2 noborder border-gray-300 dark:border-sky-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-transparent dark:text-sky-300 hover:bg-gray-50 dark:hover:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Login
                                            </button>
                                        </>
                                    }
                                </div>
                                <div className="hidden lg:block lg:ml-4">
                                    <div className="flex items-center">


                                        {
                                            user &&
                                            <Menu as="div" className="ml-4 relative flex-shrink-0 mr-4">
                                                <Menu.Button className="dark:bg-gray-800 rounded-full flex text-sm dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                    <span className="sr-only">Open user menu</span>
                                                    {user.photoURL &&
                                                        <img
                                                            className="h-8 w-8 rounded-full border border-gray-300"
                                                            src={user.photoURL}
                                                            alt=""
                                                        />
                                                    }

                                                    {!user.photoURL &&
                                                        <svg className="h-8 w-8 rounded-full text-gray-300 border border-gray-300" fill="currentColor"
                                                             viewBox="0 0 24 24">
                                                            <path
                                                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                        </svg>
                                                    }
                                                </Menu.Button>
                                                {/*<Transition*/}
                                                {/*    as={Fragment}*/}
                                                {/*    enter="transition ease-out duration-100"*/}
                                                {/*    enterFrom="transform opacity-0 scale-95"*/}
                                                {/*    enterTo="transform opacity-100 scale-100"*/}
                                                {/*    leave="transition ease-in duration-75"*/}
                                                {/*    leaveFrom="transform opacity-100 scale-100"*/}
                                                {/*    leaveTo="transform opacity-0 scale-95"*/}
                                                {/*>*/}
                                                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-60 rounded-md dark:border-gray-700 border-2 shadow-lg py-1 dark:pt-0 bg-white dark:bg-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none">

                                                    <div className="flex items-center px-5 border-b-2 dark:border-gray-700">
                                                        <div className={"my-2"}>
                                                            <div
                                                                className="text-base font-medium text-black dark:text-white">{user.displayName ?? 'Anonymous'}</div>
                                                            {/*<div className="text-sm font-medium dark:text-gray-400">{user.email}</div>*/}
                                                            {/*<div className="text-sm font-medium text-gray-400 dark:text-gray-500">*/}
                                                            {/*    Member since 4/9/2022*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>


                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link to="/profile"
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                        'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                                    )}
                                                                >
                                                                    My Profile
                                                            </Link>
                                                        )}
                                                    </Menu.Item>

                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link to="/settings"
                                                                  className={classNames(
                                                                      active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                      'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                                  )}
                                                            >
                                                                Settings
                                                            </Link>
                                                        )}
                                                    </Menu.Item>

                                                    {/*<Menu.Item>*/}
                                                    {/*    {({ active }) => (*/}
                                                    {/*        <Link href={"/settings"}>*/}
                                                    {/*            <a*/}
                                                    {/*                className={classNames(*/}
                                                    {/*                    active ? 'bg-gray-100 dark:bg-gray-800' : '',*/}
                                                    {/*                    'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'*/}
                                                    {/*                )}*/}
                                                    {/*            >*/}
                                                    {/*                Settings*/}
                                                    {/*            </a>*/}
                                                    {/*        </Link>*/}
                                                    {/*    )}*/}
                                                    {/*</Menu.Item>*/}
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link to="/api/auth/signout"
                                                                    onClick={handleSignout}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 dark:bg-gray-800' : '',
                                                                        'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                                    )}
                                                                >
                                                                    Sign out
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                                {/*</Transition>*/}
                                            </Menu>
                                        }


                                        {
                                            !user &&
                                            <>
                                                {/*<LoginButton></LoginButton>*/}
                                                <button onClick={() => setOpen2(true)}
                                                   className="cursor-pointer m-5 inline-flex items-center px-4 py-2 border border-gray-300 dark:border-sky-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-transparent dark:text-sky-300 hover:bg-gray-50 dark:hover:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                    Sign up
                                                </button>


                                                <button onClick={() => setOpen(true)}
                                                   className="cursor-pointer m-5 inline-flex items-center px-4 py-2 noborder border-gray-300 dark:border-sky-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-transparent dark:text-sky-300 hover:bg-gray-50 dark:hover:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                    Login
                                                </button>
                                            </>
                                        }


                                        {/*<Link href={"/notifications"}>*/}
                                        {/*    <button*/}
                                        {/*        type="button"*/}
                                        {/*        className="flex-shrink-0 dark:bg-gray-800 p-1 rounded-full dark:text-gray-400 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"*/}
                                        {/*    >*/}
                                        {/*        <span className="sr-only">View notifications</span>*/}
                                        {/*        <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                                        {/*    </button>*/}
                                        {/*</Link>*/}

                                        {/* Profile dropdown */}


                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            user &&
                            <Disclosure.Panel className="lg:hidden">
                                {/*<div className="px-2 pt-2 pb-3 space-y-1">*/}
                                {/*    /!* Current: "dark:bg-gray-900 dark:text-white", Default: "dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white" *!/*/}
                                {/*    <Disclosure.Button*/}
                                {/*        as="a"*/}
                                {/*        href="#"*/}
                                {/*        className="dark:bg-gray-900 dark:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
                                {/*    >*/}
                                {/*        Dashboard*/}
                                {/*    </Disclosure.Button>*/}
                                {/*    <Disclosure.Button*/}
                                {/*        as="a"*/}
                                {/*        href="#"*/}
                                {/*        className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
                                {/*    >*/}
                                {/*        Team*/}
                                {/*    </Disclosure.Button>*/}
                                {/*    <Disclosure.Button*/}
                                {/*        as="a"*/}
                                {/*        href="#"*/}
                                {/*        className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
                                {/*    >*/}
                                {/*        Projects*/}
                                {/*    </Disclosure.Button>*/}
                                {/*    <Disclosure.Button*/}
                                {/*        as="a"*/}
                                {/*        href="#"*/}
                                {/*        className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
                                {/*    >*/}
                                {/*        Calendar*/}
                                {/*    </Disclosure.Button>*/}
                                {/*</div>*/}
                                <div className="pt-4 pb-3 border-t border-gray-700">
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            {user.photoURL &&
                                                <img
                                                    className="h-8 w-8 rounded-full border border-gray-300"
                                                    src={user.photoURL}
                                                    alt=""
                                                />
                                            }

                                            {!user.photoURL &&
                                                <svg className="h-8 w-8 rounded-full text-gray-300 border border-gray-300" fill="currentColor"
                                                     viewBox="0 0 24 24">
                                                    <path
                                                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                </svg>
                                            }
                                        </div>
                                        <div className="ml-3">
                                            <div
                                                className="text-base font-medium dark:text-white">{user?.displayName ?? 'Anonymous'}</div>
                                            {/*<div className="text-sm font-medium dark:text-gray-400">{user.email}</div>*/}
                                            {/*<div className="text-sm font-medium text-gray-400 dark:text-gray-600">*/}
                                            {/*    Member since 4/9/2022*/}
                                            {/*</div>*/}
                                        </div>
                                        {/*<button*/}
                                        {/*    type="button"*/}
                                        {/*    className="ml-auto flex-shrink-0 dark:bg-gray-800 p-1 rounded-full dark:text-gray-400 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"*/}
                                        {/*>*/}
                                        {/*    <span className="sr-only">View notifications</span>*/}
                                        {/*    <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                                        {/*</button>*/}


                                    </div>
                                    <div className="mt-3 px-2 space-y-1">
                                        <Link to="/profile"
                                              className="block px-3 py-2 rounded-md text-base font-medium dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
                                            >
                                                My Profile
                                        </Link>
                                        {/*<Link href={"/settings"}>*/}
                                        {/*    <a*/}
                                        {/*        className="block px-3 py-2 rounded-md text-base font-medium dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"*/}
                                        {/*    >*/}
                                        {/*        Settings*/}
                                        {/*    </a>*/}
                                        {/*</Link>*/}
                                        <Disclosure.Button
                                            as="a"
                                            href={`/api/auth/signout`}
                                            onClick={handleSignout}
                                            className="block px-3 py-2 rounded-md text-base font-medium dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
                                        >
                                            Sign out
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        }



                        {/*{*/}
                        {/*    !user &&*/}
                        {/*    // <Disclosure.Panel className="lg:hidden">*/}
                        {/*    //     <button onClick={() => setOpen(true)}*/}
                        {/*    //             className="block px-3 py-2 rounded-md text-base font-medium dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">*/}
                        {/*    //         Sign in!*/}
                        {/*    //     </button>*/}
                        {/*<div className="px-2 pt-2 pb-3 space-y-1">*/}
                        {/*    /!* Current: "dark:bg-gray-900 dark:text-white", Default: "dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white" *!/*/}
                        {/*    <Disclosure.Button*/}
                        {/*        as="a"*/}
                        {/*        href="#"*/}
                        {/*        className="dark:bg-gray-900 dark:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
                        {/*    >*/}
                        {/*        Dashboard*/}
                        {/*    </Disclosure.Button>*/}
                        {/*    <Disclosure.Button*/}
                        {/*        as="a"*/}
                        {/*        href="#"*/}
                        {/*        className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
                        {/*    >*/}
                        {/*        Team*/}
                        {/*    </Disclosure.Button>*/}
                        {/*    <Disclosure.Button*/}
                        {/*        as="a"*/}
                        {/*        href="#"*/}
                        {/*        className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
                        {/*    >*/}
                        {/*        Projects*/}
                        {/*    </Disclosure.Button>*/}
                        {/*    <Disclosure.Button*/}
                        {/*        as="a"*/}
                        {/*        href="#"*/}
                        {/*        className="dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
                        {/*    >*/}
                        {/*        Calendar*/}
                        {/*    </Disclosure.Button>*/}
                        {/*</div>*/}
                        {/*<LoginButton></LoginButton>*/}
                        {/*</Disclosure.Panel>*/}
                        {/*// }*/}


                    </>
                )}
            </Disclosure>

            <Dialog open={open} as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center zmin-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-black bg-opacity-75 dark:bg-opacity-75 transition-opacity" />

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                      </span>
                    <div className="relative inline-block align-bottom bg-white dark:bg-slate-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                            <button
                                type="button"
                                className="bg-white dark:bg-black rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => setOpen(false)}
                            >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:text-left w-full">
                                <div className="mt-2">

                                    <div className="min-h-full flex flex-col justify-center pb-8 sm:px-6 lg:px-8 w-full">
                                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                            {/*<img*/}
                                            {/*    className="mx-auto h-12 w-auto"*/}
                                            {/*    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"*/}
                                            {/*    alt="Workflow"*/}
                                            {/*/>*/}
                                            <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900 dark:text-gray-100">Login</h2>
                                        </div>

                                        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

                                            <form className="space-y-6" onSubmit={(e) => handleSignIn(e)}>
                                                <div>
                                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                                        Email address
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type={"email"} id={"email"} name={"email"} autoComplete={"email"} value={email} required
                                                               className="appearance-none block w-full px-3 py-2 border text-black dark:bg-gray-800 dark:text-gray-50 border-gray-300 dark:border-gray-600 focus:dark:border-sky-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                               onChange={(e) => setEmail(e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                                        Password
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type={"password"} id={"password"} name={"password"} autoComplete={"current-password"} value={password} required
                                                               className="appearance-none block w-full px-3 py-2 border text-black dark:bg-gray-800 dark:text-gray-50 border-gray-300 dark:border-gray-600 focus:dark:border-sky-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                               onChange={(e) => setPassword(e.target.value)}/>
                                                    </div>
                                                </div>

                                                {/*<div className="text-sm text-center">*/}
                                                {/*    <a href="#" className="font-medium text-sky-600 hover:text-sky-500">*/}
                                                {/*        Forgot your password?*/}
                                                {/*    </a>*/}
                                                {/*</div>*/}

                                                <button
                                                    type="submit"
                                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                                >
                                                    Login
                                                </button>


                                                {/*<button className="login__btn login__google" onClick={signInWithGoogle}>*/}
                                                {/*    Login with Google*/}
                                                {/*</button>*/}


                                                {
                                                    signInError &&
                                                    <div className={"text-red-500"}>
                                                        { signInError }
                                                    </div>
                                                }

                                            </form>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog open={open2} as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen2}>
                <div className="flex items-end justify-center zmin-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-black bg-opacity-75 dark:bg-opacity-75 transition-opacity" />

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                      </span>
                    <div className="relative inline-block align-bottom bg-white dark:bg-slate-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                            <button
                                type="button"
                                className="bg-white dark:bg-black rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => setOpen2(false)}
                            >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:text-left w-full">
                                <div className="mt-2">

                                    <div className="min-h-full flex flex-col justify-center pb-8 sm:px-6 lg:px-8 w-full">
                                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                            {/*<img*/}
                                            {/*    className="mx-auto h-12 w-auto"*/}
                                            {/*    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"*/}
                                            {/*    alt="Workflow"*/}
                                            {/*/>*/}
                                            <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900 dark:text-gray-100">Sign Up</h2>
                                        </div>

                                        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

                                            <form className="space-y-6" onSubmit={(e) => handleSignUp(e)}>
                                                {/*<div>*/}
                                                {/*    <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-400">*/}
                                                {/*        Unique public name*/}
                                                {/*    </label>*/}
                                                {/*    <div className="mt-1">*/}
                                                {/*        <input type={"text"} id={"name"} name={"name"} autoComplete={"username"} value={name}*/}
                                                {/*               className="appearance-none block w-full px-3 py-2 border text-black dark:bg-gray-800 dark:text-gray-50 border-gray-300 dark:border-gray-600 focus:dark:border-sky-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-sky-500 sm:text-sm"*/}
                                                {/*               placeholder={"Letters and numbers only"}*/}
                                                {/*               onChange={(e) => setName(e.target.value)}/>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}

                                                <div>
                                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                                        Email address
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type={"email"} id={"email"} name={"email"} autoComplete={"email"} value={email}
                                                               className="appearance-none block w-full px-3 py-2 border text-black dark:bg-gray-800 dark:text-gray-50 border-gray-300 dark:border-gray-600 focus:dark:border-sky-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                               onChange={(e) => setEmail(e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                                        Password
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type={"password"} id={"password"} name={"password"} autoComplete={"current-password"} value={password}
                                                               className="appearance-none block w-full px-3 py-2 border text-black dark:bg-gray-800 dark:text-gray-50 border-gray-300 dark:border-gray-600 focus:dark:border-sky-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                               onChange={(e) => setPassword(e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className={"text-gray-700 dark:text-gray-500 text-sm"}>
                                                    To create an account, you must be at least 13 years old and agree
                                                    to the <Link to={"/terms"} className={"underline"} target={"_blank"}>Terms of Service</Link>.
                                                </div>

                                                <button
                                                    type="submit"
                                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                                >
                                                    Create Account
                                                </button>

                                                {
                                                    signUpError &&
                                                    <div className={"text-red-500"}>
                                                        { signUpError }
                                                    </div>
                                                }


                                            </form>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>


    )

}

export default NavBar;
