import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function User(props: any) {

    // type Comment = {
    //     id: string,
    //     upvotes?: number,
    //     downvotes?: number,
    //     body?: string
    // }

    type UserDB = {
        id: string,
        name: string,
        username: string,
        votes: any
    }

    // const search = window.location.search;
    // const params = new URLSearchParams(search);

    // const [user, loading, error] = useAuthState(auth);
    //const [comments, setComments] = useState(Array<Comment>())
    const [userDB, setUserDB] = useState<UserDB>({
        id: '',
        name: '',
        username: '',
        votes: []
    })
    //const [fetching, setFetching] = useState(true)

    let { username } = useParams();

    useEffect(() => {
        document.title = "@" + userDB.username + " - Sum It Up"
    }, [userDB]);


    useEffect(() => {

        const getUser = () => {

            axios.get(process.env.REACT_APP_API_URL + '/api/users/' + username, {
                //token: idToken
                // headers: {
                //     // "Authorization": `Bearer ${theLoginToken}`
                //     "Authorization": `Bearer ${idToken}`
                // }
            }).then(response => {
                console.log(response);
                //setComments(response.data ?? []);
                setUserDB(response.data);
                //setDisplayName(response.data.user.displayName)
            });

            // const commentsRef = collection(db, `users/${user.uid}/comments`);
            //
            // const q = query(commentsRef)
            //
            //
            // let theComments;
            // const querySnapshot = await getDocs(q);
            // querySnapshot.forEach((doc) => {
            //     // doc.data() is never undefined for query doc snapshots
            //     //console.log(doc.id, " => ", doc.data());
            //     theComments = querySnapshot.docs.map(doc => ({
            //         id: doc.id,
            //         ...doc.data()
            //     }));
            // });

            //setComments(theComments ?? [])

            //setFetching(false)
        }

        getUser();

    }, [ username ])

    return (
        <>
            {userDB &&
                <>
                    <div className="max-w-7xl w-full mx-auto px-2 sm:px-4 lg:px-8">
                        <main className="flex flex-col items-center justify-center w-full flex-1 px-20 text-center">
                            <h1 className="text-3xl font-bold text-sky-600 dark:text-sky-400 mb-3 mt-16">
                                { userDB.name }
                            </h1>
                            <Link to={`/user/${userDB.username}`} className={"text-xl font-bold text-sky-300 dark:text-sky-200 mb-8"}>#{ userDB.username }</Link>
                        </main>

                        <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Last 30 days</h3>
                            <dl className="mt-5 grid grid-cols-1 gap-3 grid-cols-2 md:grid-cols-4">
                                <div className="px-4 py-5 shadow rounded-lg overflow-hidden sm:p-6">
                                    <dt className="text-sm font-medium text-gray-500 truncate">👍 Received</dt>
                                    <dd className="mt-1 text-3xl font-semibold text-green-700 dark:text-green-400">{ userDB.votes?.received?.upvotes }</dd>
                                </div>
                                <div className="px-4 py-5 shadow rounded-lg overflow-hidden sm:p-6">
                                    <dt className="text-sm font-medium text-gray-500 truncate">👎 Received</dt>
                                    <dd className="mt-1 text-3xl font-semibold text-red-700 dark:text-red-400">{ userDB.votes?.received?.downvotes }</dd>
                                </div>
                                <div className="px-4 py-5 shadow rounded-lg overflow-hidden sm:p-6">
                                    <dt className="text-sm font-medium text-gray-500 truncate">👍 Given</dt>
                                    <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-gray-100">{ userDB.votes?.given?.upvotes }</dd>
                                </div>
                                <div className="px-4 py-5 shadow rounded-lg overflow-hidden sm:p-6">
                                    <dt className="text-sm font-medium text-gray-500 truncate">👎 Given</dt>
                                    <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-gray-100">{ userDB.votes?.given?.downvotes }</dd>
                                </div>
                            </dl>
                        </div>


                        {/*<div className="max-w-7xl w-full mx-auto px-2 sm:px-4 lg:px-8">*/}
                        {/*    /!*{ JSON.stringify(comments)}*!/*/}
                        {/*    {comments &&*/}
                        {/*        <>*/}
                        {/*            {comments.map((comment: Comment) => (*/}
                        {/*                <Comment key={comment.id} comment={comment}/>*/}
                        {/*            ))}*/}
                        {/*        </>*/}
                        {/*    }*/}

                        {/*    {!comments &&*/}
                        {/*        <>*/}
                        {/*            You haven't made any posts yet.*/}
                        {/*        </>*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>
                </>
            }

        </>
    )
}
