import React, {useContext, useEffect, useState} from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import axios, {AxiosError} from 'axios';
import Select from 'react-select'
import { components } from "react-select";
import './Settings.css'
import {AppContext} from "./AppContext";

export default function Settings() {

    useEffect(() => {
        document.title = "Settings - Sum It Up"
    }, []);

    const state = useContext(AppContext)
    const [user] = useAuthState(auth);

    const languagesArray = [
        ['Arabic', 'ar'],
        ['Bengali (Bangla)', 'bn'],
        ['Chinese', 'zh'],
        ['English', 'en'],
        ['French', 'fr'],
        ['German', 'de'],
        ['Hindi', 'hi'],
        ['Indonesian', 'id, in'],
        ['Italian', 'it'],
        ['Japanese', 'ja'],
        ['Portuguese', 'pt'],
        ['Russian', 'ru'],
        ['Spanish', 'es'],
        ['Urdu', 'ur'],

        ['Abkhazian', 'ab'],
        ['Afar', 'aa'],
        ['Afrikaans', 'af'],
        ['Akan', 'ak'],
        ['Albanian', 'sq'],
        ['Amharic', 'am'],
        ['Aragonese', 'an'],
        ['Armenian', 'hy'],
        ['Assamese', 'as'],
        ['Avaric', 'av'],
        ['Avestan', 'ae'],
        ['Aymara', 'ay'],
        ['Azerbaijani', 'az'],
        ['Bambara', 'bm'],
        ['Bashkir', 'ba'],
        ['Basque', 'eu'],
        ['Belarusian', 'be'],
        ['Bihari', 'bh'],
        ['Bislama', 'bi'],
        ['Bosnian', 'bs'],
        ['Breton', 'br'],
        ['Bulgarian', 'bg'],
        ['Burmese', 'my'],
        ['Catalan', 'ca'],
        ['Chamorro', 'ch'],
        ['Chechen', 'ce'],
        ['Chichewa, Chewa, Nyanja', 'ny'],
        // ['Chinese (Simplified)', 'zh-Hans'],
        // ['Chinese (Traditional)', 'zh-Hant'],
        ['Chuvash', 'cv'],
        ['Cornish', 'kw'],
        ['Corsican', 'co'],
        ['Cree', 'cr'],
        ['Croatian', 'hr'],
        ['Czech', 'cs'],
        ['Danish', 'da'],
        ['Divehi, Dhivehi, Maldivian', 'dv'],
        ['Dutch', 'nl'],
        ['Dzongkha', 'dz'],
        ['Esperanto', 'eo'],
        ['Estonian', 'et'],
        ['Ewe', 'ee'],
        ['Faroese', 'fo'],
        ['Fijian', 'fj'],
        ['Finnish', 'fi'],
        ['Fula, Fulah, Pulaar, Pular', 'ff'],
        ['Galician', 'gl'],
        ['Gaelic (Scottish)', 'gd'],
        ['Gaelic (Manx)', 'gv'],
        ['Georgian', 'ka'],
        ['Greek', 'el'],
        ['Greenlandic', 'kl'],
        ['Guarani', 'gn'],
        ['Gujarati', 'gu'],
        ['Haitian Creole', 'ht'],
        ['Hausa', 'ha'],
        ['Hebrew', 'he'],
        ['Herero', 'hz'],
        ['Hiri Motu', 'ho'],
        ['Hungarian', 'hu'],
        ['Icelandic', 'is'],
        ['Ido', 'io'],
        ['Igbo', 'ig'],
        ['Interlingua', 'ia'],
        ['Interlingue', 'ie'],
        ['Inuktitut', 'iu'],
        ['Inupiak', 'ik'],
        ['Irish', 'ga'],
        ['Javanese', 'jv'],
        ['Kalaallisut, Greenlandic', 'kl'],
        ['Kannada', 'kn'],
        ['Kanuri', 'kr'],
        ['Kashmiri', 'ks'],
        ['Kazakh', 'kk'],
        ['Khmer', 'km'],
        ['Kikuyu', 'ki'],
        ['Kinyarwanda (Rwanda)', 'rw'],
        ['Kirundi', 'rn'],
        ['Kyrgyz', 'ky'],
        ['Komi', 'kv'],
        ['Kongo', 'kg'],
        ['Korean', 'ko'],
        ['Kurdish', 'ku'],
        ['Kwanyama', 'kj'],
        ['Lao', 'lo'],
        ['Latin', 'la'],
        ['Latvian (Lettish)', 'lv'],
        ['Limburgish (Limburger)', 'li'],
        ['Lingala', 'ln'],
        ['Lithuanian', 'lt'],
        ['Luga-Katanga', 'lu'],
        ['Luganda, Ganda', 'lg'],
        ['Luxembourgish', 'lb'],
        ['Manx', 'gv'],
        ['Macedonian', 'mk'],
        ['Malagasy', 'mg'],
        ['Malay', 'ms'],
        ['Malayalam', 'ml'],
        ['Maltese', 'mt'],
        ['Maori', 'mi'],
        ['Marathi', 'mr'],
        ['Marshallese', 'mh'],
        ['Moldavian', 'mo'],
        ['Mongolian', 'mn'],
        ['Nauru', 'na'],
        ['Navajo', 'nv'],
        ['Ndonga', 'ng'],
        ['Northern Ndebele', 'nd'],
        ['Nepali', 'ne'],
        ['Norwegian', 'no'],
        ['Norwegian bokmål', 'nb'],
        ['Norwegian nynorsk', 'nn'],
        ['Nuosu', 'ii'],
        ['Occitan', 'oc'],
        ['Ojibwe', 'oj'],
        ['Old Church Slavonic, Old Bulgarian', 'cu'],
        ['Oriya', 'or'],
        ['Oromo (Afaan Oromo)', 'om'],
        ['Ossetian', 'os'],
        ['Pāli', 'pi'],
        ['Pashto, Pushto', 'ps'],
        ['Persian (Farsi)', 'fa'],
        ['Polish', 'pl'],
        ['Punjabi (Eastern)', 'pa'],
        ['Quechua', 'qu'],
        ['Romansh', 'rm'],
        ['Romanian', 'ro'],
        ['Sami', 'se'],
        ['Samoan', 'sm'],
        ['Sango', 'sg'],
        ['Sanskrit', 'sa'],
        ['Serbian', 'sr'],
        ['Serbo-Croatian', 'sh'],
        ['Sesotho', 'st'],
        ['Setswana', 'tn'],
        ['Shona', 'sn'],
        ['Sichuan Yi', 'ii'],
        ['Sindhi', 'sd'],
        ['Sinhalese', 'si'],
        ['Siswati', 'ss'],
        ['Slovak', 'sk'],
        ['Slovenian', 'sl'],
        ['Somali', 'so'],
        ['Southern Ndebele', 'nr'],
        ['Sundanese', 'su'],
        ['Swahili (Kiswahili)', 'sw'],
        ['Swati', 'ss'],
        ['Swedish', 'sv'],
        ['Tagalog', 'tl'],
        ['Tahitian', 'ty'],
        ['Tajik', 'tg'],
        ['Tamil', 'ta'],
        ['Tatar', 'tt'],
        ['Telugu', 'te'],
        ['Thai', 'th'],
        ['Tibetan', 'bo'],
        ['Tigrinya', 'ti'],
        ['Tonga', 'to'],
        ['Tsonga', 'ts'],
        ['Turkish', 'tr'],
        ['Turkmen', 'tk'],
        ['Twi', 'tw'],
        ['Uyghur', 'ug'],
        ['Ukrainian', 'uk'],
        ['Uzbek', 'uz'],
        ['Venda', 've'],
        ['Vietnamese', 'vi'],
        ['Volapük', 'vo'],
        ['Wallon', 'wa'],
        ['Welsh', 'cy'],
        ['Wolof', 'wo'],
        ['Western Frisian', 'fy'],
        ['Xhosa', 'xh'],
        ['Yiddish', 'yi, ji'],
        ['Yoruba', 'yo'],
        ['Zhuang, Chuang', 'za'],
        ['Zulu', 'zu'],
    ];
    let languages : any = [];
    let preselectedLanguages : any = [];
    languagesArray.map((language, i) => {
        languages[i] = {
            label: language[0],
            value: language[1]
        }

        if (navigator.language.substring(0, 2) === language[1]) {
            //preselectedLanguages = languages[i];
        }

        return languages;
    });

    // const customStyles = {
    //     option: (provided:any, state:any) => ({
    //         ...provided,
    //         // borderBottom: '1px dotted pink',
    //         // color: state.isSelected ? 'red' : 'blue',
    //         // padding: 20,
    //     }),
    //     control: () => ({
    //         // none of react-select's styles are passed to <Control />
    //         // width: 200,
    //     }),
    //     singleValue: (provided:any, state:any) => {
    //         const opacity = state.isDisabled ? 0.5 : 1;
    //         const transition = 'opacity 300ms';
    //
    //         return { ...provided, opacity, transition };
    //     }
    // }

    // if (! user) {
    //     return ('Not logged in')
    // }

    const [myLanguage, setMyLanguage] = useState(preselectedLanguages)
    // const [myLanguage, setMyLanguage] = useState(navigator.language.substring(0, 2) || 'en')
    // const [country, setCountry] = useState('US');
    // const [displayName, setDisplayName] = useState(user?.displayName);
    // const [publicProfile, setPublicProfile] = useState(''); // @todo need to get document from Firestore fist


    useEffect(() => {

        const loadSettings = () => {
            if (! user) {
                return;
            }

            if (! state.firebaseJWT) {
                return;
            }

            axios.get(process.env.REACT_APP_API_URL + '/api/myself/settings', {
                //token: idToken
                headers: {
                    // "Authorization": `Bearer ${theLoginToken}`
                    "Authorization": `Bearer ${state.firebaseJWT}`,
                    'Accept': 'application/json',
                }
            }).then(response => {
                console.log(response);
                //setComments(response.data ?? []);
                let selectedLanguages : any = [];
                // response.data.languages_for_react.map((language: any, i: any) => {
                languages.map((language: any, i: any) => {
                    //console.log(language)
                    response.data.languages_for_react.map((language2: any, i2: any) => {
                        if (language2 === language.value) {
                            selectedLanguages[i] = language;
                        }

                        return selectedLanguages;

                    })

                    return selectedLanguages;
                })

                if (selectedLanguages.length > 0) {
                    setMyLanguage(selectedLanguages)
                }

                //setDisplayName(response.data.user.displayName)
            })
                .catch((reason: AxiosError) => {
                    if (reason.response!.status === 401) {
                        localStorage.removeItem("firebaseJWT");
                        state.setFirebaseJWT("");
                    }
                });
        }

        loadSettings();
        // eslint-disable-next-line
    }, [ user, state ]);


    const Option = (props: any) => {
        return (
            <div>
                <components.Option {...props}  className={"language-select-option"}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const handleChange = (selected: any) => {
        setMyLanguage(selected)
    };

    const saveSettings = (e: any) => {
        e.preventDefault()

        if (! user) {
            return;
        }

        if (! state.firebaseJWT) {
            return;
        }

        axios.post(process.env.REACT_APP_API_URL + '/api/myself/settings', {
            token: state.firebaseJWT,
            myLanguages: myLanguage,
        }).then(response => {
            console.log(response);
            //alert(JSON.stringify(response.data));
        })
            .catch((reason: AxiosError) => {
                if (reason.response!.status === 401) {
                    localStorage.removeItem("firebaseJWT");
                    state.setFirebaseJWT("");
                }
            });
    }

    return (
        <>

            <main className="flex flex-col items-center justify-center w-full flex-1 px-20 text-center">
                <h1 className="text-5xl font-bold text-sky-600 dark:text-sky-400 mb-16 mt-16">
                    Settings
                </h1>
            </main>

            {!user &&
                <>
                    <div className={"text-center"}>
                        Please login to update your settings.
                    </div>
                </>
            }

            {user &&

                <div className={"max-w-7xl mx-auto sm:px-1 md:px-10"}>

                    <div>
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50">Language</h3>
                                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
                                        This determines what messages will be visible by default.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-2">
                                <form action="#" method="POST">
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="px-4 py-5 bg-white dark:bg-slate-900 space-y-6 sm:p-6">

                                            <div className="sm:col-span-4 mb-20">
                                                <label htmlFor="my_languages"
                                                       className="block text-sm font-medium text-gray-700 dark:text-slate-400">
                                                    Languages to show
                                                </label>
                                                <div className="mt-1">

                                                    <Select
                                                        options={languages}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option
                                                        }}
                                                        onChange={handleChange}
                                                        value={myLanguage}
                                                        className={"language-select"}
                                                        // styles={customStyles}
                                                    />

                                                    {/*<Select options={languages} />*/}
                                                    {/*<Select options={languages} value={myLanguage} isMulti={true} />*/}
                                                    {/*<Select options={languages} value={"myLanguage"} onChange={(e) => setMyLanguage(this.getValue())} />*/}
                                                    {/*<input id="my_languages" name="display_name" type="text"*/}
                                                    {/*       onChange={(e) => setMyLanguage(e.target.value)}*/}
                                                    {/*       value={myLanguage ?? ''}*/}
                                                    {/*       className="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-slate-800 p-2"/>*/}
                                                </div>
                                            </div>

                                            <br/>

                                            {/*<label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-slate-400">*/}
                                            {/*    Country*/}
                                            {/*</label>*/}
                                            {/*<ReactFlagsSelect*/}
                                            {/*    selected={country}*/}
                                            {/*    onSelect={code => setCountry(code)}*/}
                                            {/*    searchable*/}
                                            {/*    className={"country-select"}*/}
                                            {/*/>*/}

                                            {/*<div>*/}
                                            {/*    <label htmlFor="about" className="block text-sm font-medium text-gray-700 dark:text-slate-400">*/}
                                            {/*        About*/}
                                            {/*    </label>*/}
                                            {/*    <div className="mt-1">*/}
                                            {/*      <textarea*/}
                                            {/*          id="about"*/}
                                            {/*          name="about"*/}
                                            {/*          rows={10}*/}
                                            {/*          className="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-slate-800"*/}
                                            {/*          placeholder="(Optional) Write about yourself..."*/}
                                            {/*          defaultValue={''}*/}
                                            {/*      />*/}
                                            {/*    </div>*/}
                                            {/*    <p className="mt-2 text-sm text-gray-500">*/}
                                            {/*        This will be visible to anyone who views your profile, including search engines.*/}
                                            {/*    </p>*/}
                                            {/*</div>*/}

                                            {/*<div className="col-span-6 sm:col-span-3">*/}
                                            {/*    <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-slate-400">*/}
                                            {/*        Country*/}
                                            {/*    </label>*/}
                                            {/*    <select*/}
                                            {/*        id="country"*/}
                                            {/*        name="country"*/}
                                            {/*        autoComplete="country-name"*/}
                                            {/*        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm dark:bg-slate-800"*/}
                                            {/*    >*/}
                                            {/*        <option>United States</option>*/}
                                            {/*        <option>Canada</option>*/}
                                            {/*        <option>Mexico</option>*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}

                                        </div>
                                        <div className="px-4 py-3 bg-gray-50 dark:bg-slate-900 text-right sm:px-6">
                                            <button
                                                type="submit"
                                                onClick={saveSettings}
                                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                    {/*<div>*/}
                    {/*    <div className="md:grid md:grid-cols-3 md:gap-6">*/}
                    {/*        <div className="md:col-span-1">*/}
                    {/*            <div className="px-4">*/}
                    {/*                <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50">Premium</h3>*/}
                    {/*                <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">*/}
                    {/*                    Upgrade to the premium plan to remove advertisements.*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="mt-5 md:mt-0 md:col-span-2">*/}
                    {/*            <form action="#" method="POST">*/}
                    {/*                <div className="shadow sm:rounded-md sm:overflow-hidden">*/}
                    {/*                    <div className="px-4 py-5 bg-white dark:bg-slate-900 space-y-6 sm:p-6">*/}

                    {/*                        <div className="sm:col-span-4 mb-20">*/}
                    {/*                            <div className="mt-1">*/}

                    {/*                               Remove advertisements*/}

                    {/*                            </div>*/}
                    {/*                        </div>*/}

                    {/*                    </div>*/}
                    {/*                    <div className="px-4 py-3 bg-gray-50 dark:bg-slate-900 text-right sm:px-6">*/}
                    {/*                        <button*/}
                    {/*                            type="submit"*/}
                    {/*                            onClick={saveSettings}*/}
                    {/*                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"*/}
                    {/*                        >*/}
                    {/*                            Save*/}
                    {/*                        </button>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </form>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="hidden sm:block" aria-hidden="true">*/}
                    {/*    <div className="py-5">*/}
                    {/*        <div className="border-t border-gray-200 dark:border-slate-600" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="mt-20 sm:mt-0 mb-20">*/}
                    {/*    <div className="md:grid md:grid-cols-3 md:gap-6">*/}
                    {/*        <div className="md:col-span-1">*/}
                    {/*            <div className="px-4">*/}
                    {/*                <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50">Delete account</h3>*/}
                    {/*                <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">Destroy your account and all of your data.</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="mt-5 md:mt-0 md:col-span-2">*/}
                    {/*            <form action="#" method="POST">*/}
                    {/*                <div className="shadow overflow-hidden sm:rounded-md">*/}
                    {/*                    <div className="px-4 py-5 bg-white dark:bg-slate-800 space-y-6 sm:p-6">*/}

                    {/*                        <div>*/}
                    {/*                            By pressing the button below, your <b>Sum It Up</b> account and all of*/}
                    {/*                            your data will be irreversibly destroyed. This includes, but is not*/}
                    {/*                            limited to, your posts, comments, summaries, and upvotes.*/}
                    {/*                        </div>*/}

                    {/*                        <button*/}
                    {/*                            type="button"*/}
                    {/*                            className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"*/}
                    {/*                        >*/}
                    {/*                            <TrashIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />*/}
                    {/*                            Delete My Account*/}
                    {/*                        </button>*/}

                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </form>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                </div>

            }

            {/*User = { JSON.stringify(user) }*/}

        </>
    )
}
