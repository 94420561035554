import React, { useEffect } from "react";
import "./Login.css";

function Homepage() {

    useEffect(() => {
        document.title = "About Us - Sum It Up"
    }, []);

    return (
        <div className="about">
            Hello - About
        </div>
    )
}

export default Homepage;
