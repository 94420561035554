import React, {useEffect} from "react";
import { FlagIcon } from '@heroicons/react/solid'

export default function Terms() {

    useEffect(() => {
        document.title = "Terms of Service - Sum It Up"
    }, []);

    return (
        <>
            <main className="w-full flex-1 px-5 md:px-20 mb-20">
                <h1 className="text-5xl font-bold text-sky-600 dark:text-sky-400 mb-16 mt-16 text-center">
                    Terms of Service
                </h1>

                <div className="mt-10 max-w-prose mx-auto text-md sm:text-lg md:text-xl text-gray-600 dark:text-gray-400">

                    <h2 className={"text-3xl font-bold mt-16 mb-4 text-gray-900 dark:text-gray-50"}>Prohibited content</h2>

                    Do not post private, sensitive, or personally identifiable information about others.
                    We respect freedom of speech, but this does not include hate speech, statements that
                    are defamatory or invade anyone's privacy, spam, or content that is illegal in the
                    United States.
                    <br/><br/>

                    If you encounter such content, click the flag icon {' '}
                    (<FlagIcon className={"inline-block w-5 h-5 text-red-500"}></FlagIcon>)
                    next to a post to report it. It will be reviewed by a moderator and appropriate action
                    will be taken.
                    <br/><br/>

                </div>

            </main>
        </>
    )
}
