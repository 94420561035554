import React, {useEffect} from "react";
import { Link } from "react-router-dom";

export default function Privacy() {

    useEffect(() => {
        document.title = "Privacy Policy - Sum It Up"
    }, []);

    return (
        <>
            <main className="w-full flex-1 px-5 md:px-20 mb-20">
                <h1 className="text-5xl font-bold text-sky-600 dark:text-sky-400 mb-16 mt-16 text-center">
                    Privacy Policy
                </h1>

                <div className="mt-10 max-w-prose mx-auto text-md sm:text-lg md:text-xl text-gray-600 dark:text-gray-400">

                    <h2 className={"text-3xl font-bold mt-16 mb-4 text-gray-900 dark:text-gray-50"}>Information we collect and store</h2>

                    When you click the "Sum It Up" button or bookmarklet, the URL and page title is sent
                    to our servers in order to fetch comments and summaries. If the URL doesn't exist in
                    our system yet, it will be created. We do not keep track of who added a page to the
                    system.
                    <br/><br/>

                    When accessing the site anonymously (meaning you're not logged in), you are only
                    able to read comments and summaries from other users, view public profiles, and
                    view other users' public posts. We do not track the pages you viewed or the content
                    you accessed.
                    <br/><br/>

                    When accessing the site as an authenticated user (meaning you're logged in), you
                    are able to...
                    <ul className="list-disc ml-14 mt-4">
                        <li>post comments and summaries</li>
                        <li>upvote or downvote other users' posts</li>
                        <li>view, update, and delete your public profile</li>
                        <li>view and delete your public posts</li>
                        <li>follow or unfollow other users</li>
                        <li>report other users' posts if they contain prohibited content (listed below)</li>
                    </ul>
                    <br/>

                    When you perform any of the actions listed above, we log your IP address
                    along with the information you provide. This information is used to prevent abuse,
                    to look up your geographic location for the sake of generating reports without
                    personally identifiable information (PII), and to automatically detect your region's
                    primary language.
                    <br/><br/>

                    Information you provide will be visible to the public and could be used to identify
                    yourself or others. Use discretion when posting sensitive information as it will be
                    indexed by search engines, Sum It Up, and other websites.



                    <h2 className={"text-3xl font-bold mt-16 mb-4 text-gray-900 dark:text-gray-50"}>Information we share with others</h2>

                    User registration and authentication is handled by Google Firebase.
                    <br/><br/>

                    Google Analytics is used to observe aggregate data. Information collected
                    is not used to identify specific individuals or their habits.
                    <br/><br/>

                    Your information is kept private and is not shared with anyone unless it violates
                    the <Link to={"/terms"} className={"underline"}>Terms of Service</Link>.

                </div>

            </main>
        </>
    )
}
