import React, {useContext, useEffect, useState} from "react";
//import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import {useAuthState} from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import axios, {AxiosError} from "axios";
import { FlagIcon } from '@heroicons/react/solid'
import {AppContext} from "../AppContext";

const Comment = (props: any) : JSX.Element => {

    const state = useContext(AppContext)
    const [user] = useAuthState(auth);

    type Comment = {
        id: string,
        num_upvotes: number,
        num_downvotes: number,
        body: string,
        uid: string,
        username: string,
        name: string,
        created_at: any,
        type: string,
        user_photo_url: string,
        page_title?: any, // This is used when getting comment from user
        page_id?: any // This is used when getting comment from user
        page_url?: any // This is used when getting comment from user
    }

    useEffect(() => {
        setComment(props.comment)
    }, [ props.comment ])

    const [hasVoted, setHasVoted] = useState(false)
    const [hasFlagged, setHasFlagged] = useState(false)

    const [comment, setComment] = useState<Comment>({
        id: '',
        num_upvotes: 0,
        num_downvotes: 0,
        body: '',
        uid: '',
        name: '',
        username: '',
        created_at: undefined,
        type: '',
        user_photo_url: ''
    })

    const saveVote = (vote: string) => (e: any) => {

        if (!user) {
            alert("Not logged in");
            return;
        }

        sendVoteToApi(vote).then(r => console.log("Done " + r));
    }

    const sendVoteToApi = async (vote: string) => {
        if (! user) {
            return;
        }

        if (! state.firebaseJWT) {
            return;
        }

        axios.post(process.env.REACT_APP_API_URL + '/api/comments/' + props.comment.id + '/vote/' + vote, {
            token: state.firebaseJWT
            // headers: {
            //     // "Authorization": `Bearer ${theLoginToken}`
            //     "Authorization": `Bearer ${idToken}`
            // }
        }).then(response => {

            console.log("Voted: " + vote + " for comment ID " + props.comment.id)
            let newComment = comment;

            if (response.data.success) {
            //     if (response.data.is_existing) {
            //         if (vote === 'upvote') {
            //             newComment.num_upvotes++;
            //             newComment.num_downvotes--;
            //         } else {
            //             newComment.num_downvotes++;
            //             newComment.num_upvotes--;
            //         }
            //     } else {
                    if (vote === 'upvote') {
                        newComment.num_upvotes++;
                    } else {
                        newComment.num_downvotes++;
                    }

                    setHasVoted(true)
                // }
            }

            console.log("upvotes is now " + newComment.num_upvotes)
            setComment({...newComment})

            console.log(response);
            //setPage(response.data);
            //setComments(response.data ?? []);
            //setDisplayName(response.data.user.displayName)
        })
            .catch((reason: AxiosError) => {
                if (reason.response!.status === 401) {
                    localStorage.removeItem("firebaseJWT");
                    state.setFirebaseJWT("");
                }
            });

        // const { id } = await db.collection('comment_votes').add({
        //     user_id: user?.uid,
        //     comment_id: props.comment.id,
        //     vote: vote,
        //     created: new Date().toISOString(),
        // });
    }

    const saveFlag = (flag: string) => (e: any) => {
        if (! user) {
            return;
        }

        if (! state.firebaseJWT) {
            return;
        }

        axios.post(process.env.REACT_APP_API_URL + '/api/comments/' + props.comment.id + '/flag', {
            token: state.firebaseJWT,
            reason: 'Reported'
        }).then(response => {
            if (response.data.success) {
                setHasFlagged(true)
            }
        })
            .catch((reason: AxiosError) => {
                if (reason.response!.status === 401) {
                    localStorage.removeItem("firebaseJWT");
                    state.setFirebaseJWT("");
                }
            });
    }

    return (
        <div className="zbg-white px-4 py-6 sm:px-6">
            <div className="flex space-x-3">
                <div className="flex-shrink-0">
                    {comment.user_photo_url &&
                        <img className="h-10 w-10 rounded-full"
                             src="{comment.user_photo_url}"
                             alt=""/>
                    }

                    {!comment.user_photo_url &&
                        <svg className="h-10 w-10 rounded-full text-gray-300 border border-gray-600" fill="currentColor"
                             viewBox="0 0 24 24">
                            <path
                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                        </svg>
                    }
                </div>
                <div className="min-w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        { comment.username &&
                            <b><Link to={`/user/${comment.username}`} className={"hover:underline"}>{comment.name ?? 'Anonymous'}</Link></b>
                        }

                        { !comment.username &&
                            <b>{comment.name ?? 'Anonymous'}</b>
                        }
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        <span title={comment.created_at}>
                            { new Date(comment.created_at).toLocaleString() }
                            {/*<TimeAgo*/}
                            {/*    datetime={comment.created}*/}
                            {/*    locale='en_US'*/}
                            {/*/>*/}
                        </span>
                    </p>
                </div>
                {/*<div className="flex-shrink-0 self-center flex">*/}
                {/*    <div className="relative z-30 inline-block text-left">*/}


                {/*    </div>*/}
                {/*</div>*/}
            </div>

            {
                comment.page_id && comment.page_title &&
                <div className={"ml-0 mt-2 text-sky-700 dark:text-sky-300"}>
                    <Link to={`/page/${comment.page_id}`} className="hover:underline">{comment.page_title}</Link>
                </div>
            }

            {
                comment.page_url &&
                <div className={"ml-0"}>
                    <span className="text-xs whitespace-nowrap w-full inline-block overflow-hidden text-gray-600 dark:text-gray-400">{comment.page_url}</span>
                </div>
            }


            <div className="ztext-sm leading-5 mt-2 bg-white border border-gray-300 dark:border-gray-700 dark:bg-black dark:bg-opacity-50 p-4 rounded-tl rounded-tr">
                {comment.body}
            </div>

            <div className="flex items-start space-x-4 dark:bg-black dark:bg-opacity-20 p-2 rounded-bl rounded-br text-gray-400">

                {!hasVoted &&

                    <>
                        <button type="button" onClick={saveVote('upvote')}
                                className="hover:text-black dark:hover:text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path
                                    d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"/>
                            </svg>
                        </button>
                        <span className="text-green-600 dark:text-green-400 text-sm">
                            {comment.num_upvotes}
                        </span>

                        <span className="text-sm px-1">
                            &bull;
                        </span>

                        <span className="text-red-600 dark:text-red-400 text-sm">
                            {comment.num_downvotes}
                        </span>
                        <button type="button" onClick={saveVote('downvote')}
                                className="hover:text-black dark:hover:text-white ml-5">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path
                                    d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z"/>
                            </svg>
                        </button>
                    </>

                }

                {hasVoted &&
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                             fill="currentColor">
                            <path
                                d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"/>
                        </svg>
                        <span className="text-green-600 dark:text-green-400 text-sm">
                            {comment.num_upvotes}
                        </span>

                        <span className="text-sm px-1">
                            &bull;
                        </span>

                        <span className="text-red-600 dark:text-red-400 text-sm">
                            {comment.num_downvotes}
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                             fill="currentColor">
                            <path
                                d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z"/>
                        </svg>
                    </>
                }

                <div className={"ml-10 -mt-1 inline-block"}>
                    {!hasFlagged &&
                        <>
                            <button type="button" onClick={saveFlag('flag')}
                                    className="text-gray-500 hover:text-black dark:hover:text-white ml-5">
                                <FlagIcon className="h-5 w-5 pr-1 inline-block" fill="currentColor" viewBox="0 0 20 20"/>
                                Report
                            </button>
                    </>
                    }

                    {hasFlagged &&
                        <>
                            <FlagIcon className="h-5 w-5 pr-1 inline-block ml-5" fill="currentColor" viewBox="0 0 20 20"/>
                            Reported
                        </>
                    }
                </div>


            </div>

        </div>
    )
}

export default Comment
