import React, {useEffect, useState, Suspense} from "react";
import Comment from "./components/Comment";
import axios from "axios";

export default function Recent() {

    useEffect(() => {
        document.title = "Recent Posts - Sum It Up"
    }, []);

    type Comment = {
        id: string,
        upvotes?: number,
        downvotes?: number,
        body?: string
    }

    //const [user] = useAuthState(auth);
    const [comments, setComments] = useState(Array<Comment>())

    useEffect(() => {

        const getComments = () => {
            // if (comments && comments.length > 0) {
            //     return;
            // }

            axios.get(process.env.REACT_APP_API_URL + '/api/comments?sort=recent', {
                headers: {
                    'Accept': 'application/json',
                }
            }).then(response => {
                console.log(response);
                setComments(response.data ?? []);
            });
        }

        getComments()
    }, [])

    return (
        <>
            <main className="flex flex-col items-center justify-center w-full flex-1 px-20 ztext-center">
                <h1 className="text-5xl font-bold text-sky-600 dark:text-sky-400 mb-16 mt-16">
                    Recent Posts
                </h1>
            </main>

            <div className="max-w-7xl w-full mx-auto px-2 sm:px-4 lg:px-8">
                <Suspense fallback={"Loading..."}>
                    {comments &&
                        <>
                            {comments.map((comment: Comment) => (
                                <Comment key={comment.id} comment={comment}/>
                            ))}
                        </>
                    }

                    { !comments &&
                        <>
                            No posts found.
                        </>
                    }
                </Suspense>
            </div>
        </>
    )
}
