import { Link } from "react-router-dom";
import React from 'react'

function Footer() {

    return (
        <footer className="flex items-center justify-center w-full h-24 border-t dark:border-t-gray-600 mt-10">
            <Link key="privacy-policy" to="/privacy" className="flex items-center justify-center">
                Privacy Policy
            </Link>
            <Link key="terms" to="/terms" className="flex items-center justify-center ml-8">
                Terms of Service
            </Link>
            <Link key="contact" to="/contact" className="flex items-center justify-center ml-8">
                Help
            </Link>
        </footer>
    )

}

export default Footer;
