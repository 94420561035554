import React, {useContext, useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import Comment from "./components/Comment";
import axios, { AxiosResponse, AxiosError } from 'axios'
import { AppContext } from './AppContext'

export default function MyPosts() {

    useEffect(() => {
        document.title = "My Posts - Sum It Up"
    }, []);

    type Comment = {
        id: string,
        upvotes?: number,
        downvotes?: number,
        body?: string
    }

    const state = useContext(AppContext)
    const [user] = useAuthState(auth);
    const [comments, setComments] = useState(Array<Comment>())
    const [ gotComments, setGotComments ] = useState(false)

    useEffect(() => {

        const getMyComments = () => {
            if (! user) {
                return;
            }

            if (! state.firebaseJWT) {
                return;
            }

            if (gotComments) {
                return;
            }

            axios.get(process.env.REACT_APP_API_URL + '/api/myself/comments', {
                //token: idToken
                headers: {
                    "Authorization": `Bearer ${state.firebaseJWT}`,
                    'Accept': 'application/json',
                }})
                .then((response: AxiosResponse) => {
                    console.log(response);
                    setComments(response.data ?? []);
                    setGotComments(true);
                })
                .catch((reason: AxiosError) => {
                    setGotComments(true);
                    if (reason.response!.status === 401) {
                        localStorage.removeItem("firebaseJWT");
                        state.setFirebaseJWT("");
                    }
                });
        }

        getMyComments()
    }, [ user, state, gotComments ])

    return (
        <>
            <main className="flex flex-col items-center justify-center w-full flex-1 px-20 ztext-center">
                <h1 className="text-5xl font-bold text-sky-600 dark:text-sky-400 mb-16 mt-16">
                    My Posts
                </h1>
            </main>

            <div className="max-w-7xl w-full mx-auto px-2 sm:px-4 lg:px-8">
                {!user &&
                    <div className={"text-center"}>
                        You aren't logged in.
                    </div>
                }

                {comments &&
                    <>
                        {comments.map((comment: Comment) => (
                            <Comment key={comment.id} comment={comment}/>
                        ))}
                    </>
                }

                { !comments &&
                    <>
                        You haven't made any posts yet.
                    </>
                }
            </div>
        </>
    )
}
