import React, {useContext, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {analytics, auth } from "../firebase";
import axios, {AxiosError} from "axios";
import {AppContext} from "../AppContext";
import {logEvent} from "firebase/analytics";

const PostMessageBox = (props: any) : JSX.Element => {

    // const [highestCommentID, setHighestCommentID] = useState(0)

    const [newBody, setNewBody] = useState('')

    const [lang, setLang] = useState(navigator.language.substring(0, 2) || 'en')

    const state = useContext(AppContext)
    const [user, loading, error] = useAuthState(auth);

    const languages = [
        // ['Language agnostic', ''],
        ['Arabic', 'ar'],
        ['Bengali (Bangla)', 'bn'],
        ['Chinese', 'zh'],
        ['English', 'en'],
        ['French', 'fr'],
        ['German', 'de'],
        ['Hindi', 'hi'],
        ['Indonesian', 'id, in'],
        ['Italian', 'it'],
        ['Japanese', 'ja'],
        ['Portuguese', 'pt'],
        ['Russian', 'ru'],
        ['Spanish', 'es'],
        ['Urdu', 'ur'],

        ['Abkhazian', 'ab'],
        ['Afar', 'aa'],
        ['Afrikaans', 'af'],
        ['Akan', 'ak'],
        ['Albanian', 'sq'],
        ['Amharic', 'am'],
        ['Aragonese', 'an'],
        ['Armenian', 'hy'],
        ['Assamese', 'as'],
        ['Avaric', 'av'],
        ['Avestan', 'ae'],
        ['Aymara', 'ay'],
        ['Azerbaijani', 'az'],
        ['Bambara', 'bm'],
        ['Bashkir', 'ba'],
        ['Basque', 'eu'],
        ['Belarusian', 'be'],
        ['Bihari', 'bh'],
        ['Bislama', 'bi'],
        ['Bosnian', 'bs'],
        ['Breton', 'br'],
        ['Bulgarian', 'bg'],
        ['Burmese', 'my'],
        ['Catalan', 'ca'],
        ['Chamorro', 'ch'],
        ['Chechen', 'ce'],
        ['Chichewa, Chewa, Nyanja', 'ny'],
        // ['Chinese (Simplified)', 'zh-Hans'],
        // ['Chinese (Traditional)', 'zh-Hant'],
        ['Chuvash', 'cv'],
        ['Cornish', 'kw'],
        ['Corsican', 'co'],
        ['Cree', 'cr'],
        ['Croatian', 'hr'],
        ['Czech', 'cs'],
        ['Danish', 'da'],
        ['Divehi, Dhivehi, Maldivian', 'dv'],
        ['Dutch', 'nl'],
        ['Dzongkha', 'dz'],
        ['Esperanto', 'eo'],
        ['Estonian', 'et'],
        ['Ewe', 'ee'],
        ['Faroese', 'fo'],
        ['Fijian', 'fj'],
        ['Finnish', 'fi'],
        ['Fula, Fulah, Pulaar, Pular', 'ff'],
        ['Galician', 'gl'],
        ['Gaelic (Scottish)', 'gd'],
        ['Gaelic (Manx)', 'gv'],
        ['Georgian', 'ka'],
        ['Greek', 'el'],
        ['Greenlandic', 'kl'],
        ['Guarani', 'gn'],
        ['Gujarati', 'gu'],
        ['Haitian Creole', 'ht'],
        ['Hausa', 'ha'],
        ['Hebrew', 'he'],
        ['Herero', 'hz'],
        ['Hiri Motu', 'ho'],
        ['Hungarian', 'hu'],
        ['Icelandic', 'is'],
        ['Ido', 'io'],
        ['Igbo', 'ig'],
        ['Interlingua', 'ia'],
        ['Interlingue', 'ie'],
        ['Inuktitut', 'iu'],
        ['Inupiak', 'ik'],
        ['Irish', 'ga'],
        ['Javanese', 'jv'],
        ['Kalaallisut, Greenlandic', 'kl'],
        ['Kannada', 'kn'],
        ['Kanuri', 'kr'],
        ['Kashmiri', 'ks'],
        ['Kazakh', 'kk'],
        ['Khmer', 'km'],
        ['Kikuyu', 'ki'],
        ['Kinyarwanda (Rwanda)', 'rw'],
        ['Kirundi', 'rn'],
        ['Kyrgyz', 'ky'],
        ['Komi', 'kv'],
        ['Kongo', 'kg'],
        ['Korean', 'ko'],
        ['Kurdish', 'ku'],
        ['Kwanyama', 'kj'],
        ['Lao', 'lo'],
        ['Latin', 'la'],
        ['Latvian (Lettish)', 'lv'],
        ['Limburgish (Limburger)', 'li'],
        ['Lingala', 'ln'],
        ['Lithuanian', 'lt'],
        ['Luga-Katanga', 'lu'],
        ['Luganda, Ganda', 'lg'],
        ['Luxembourgish', 'lb'],
        ['Manx', 'gv'],
        ['Macedonian', 'mk'],
        ['Malagasy', 'mg'],
        ['Malay', 'ms'],
        ['Malayalam', 'ml'],
        ['Maltese', 'mt'],
        ['Maori', 'mi'],
        ['Marathi', 'mr'],
        ['Marshallese', 'mh'],
        ['Moldavian', 'mo'],
        ['Mongolian', 'mn'],
        ['Nauru', 'na'],
        ['Navajo', 'nv'],
        ['Ndonga', 'ng'],
        ['Northern Ndebele', 'nd'],
        ['Nepali', 'ne'],
        ['Norwegian', 'no'],
        ['Norwegian bokmål', 'nb'],
        ['Norwegian nynorsk', 'nn'],
        ['Nuosu', 'ii'],
        ['Occitan', 'oc'],
        ['Ojibwe', 'oj'],
        ['Old Church Slavonic, Old Bulgarian', 'cu'],
        ['Oriya', 'or'],
        ['Oromo (Afaan Oromo)', 'om'],
        ['Ossetian', 'os'],
        ['Pāli', 'pi'],
        ['Pashto, Pushto', 'ps'],
        ['Persian (Farsi)', 'fa'],
        ['Polish', 'pl'],
        ['Punjabi (Eastern)', 'pa'],
        ['Quechua', 'qu'],
        ['Romansh', 'rm'],
        ['Romanian', 'ro'],
        ['Sami', 'se'],
        ['Samoan', 'sm'],
        ['Sango', 'sg'],
        ['Sanskrit', 'sa'],
        ['Serbian', 'sr'],
        ['Serbo-Croatian', 'sh'],
        ['Sesotho', 'st'],
        ['Setswana', 'tn'],
        ['Shona', 'sn'],
        ['Sichuan Yi', 'ii'],
        ['Sindhi', 'sd'],
        ['Sinhalese', 'si'],
        ['Siswati', 'ss'],
        ['Slovak', 'sk'],
        ['Slovenian', 'sl'],
        ['Somali', 'so'],
        ['Southern Ndebele', 'nr'],
        ['Sundanese', 'su'],
        ['Swahili (Kiswahili)', 'sw'],
        ['Swati', 'ss'],
        ['Swedish', 'sv'],
        ['Tagalog', 'tl'],
        ['Tahitian', 'ty'],
        ['Tajik', 'tg'],
        ['Tamil', 'ta'],
        ['Tatar', 'tt'],
        ['Telugu', 'te'],
        ['Thai', 'th'],
        ['Tibetan', 'bo'],
        ['Tigrinya', 'ti'],
        ['Tonga', 'to'],
        ['Tsonga', 'ts'],
        ['Turkish', 'tr'],
        ['Turkmen', 'tk'],
        ['Twi', 'tw'],
        ['Uyghur', 'ug'],
        ['Ukrainian', 'uk'],
        ['Uzbek', 'uz'],
        ['Venda', 've'],
        ['Vietnamese', 'vi'],
        ['Volapük', 'vo'],
        ['Wallon', 'wa'],
        ['Welsh', 'cy'],
        ['Wolof', 'wo'],
        ['Western Frisian', 'fy'],
        ['Xhosa', 'xh'],
        ['Yiddish', 'yi, ji'],
        ['Yoruba', 'yo'],
        ['Zhuang, Chuang', 'za'],
        ['Zulu', 'zu'],
    ];

    const PostMessage = async () => {

        if (! user) {
            alert("Not logged in")
            return;
        }

        if (loading) {
            console.log("Issue: loading")
            return;
        }

        if (error) {
            console.log("Issue: error")
            return;
        }

        if (! state.firebaseJWT) {
            return;
        }

        if (! props.page?.id) {
            alert("Missing page")
            return;
        }

        try {

            axios.post(process.env.REACT_APP_API_URL + '/api/pages/' + props.page.id + '/comments', {
                body: newBody,
                lang: lang,
                type: props.type,
                token: state.firebaseJWT,
                headers: {
                    // "Authorization": `Bearer ${idToken}`,
                    'Accept': 'application/json',
                }
            }).then(response => {
                console.log(response);
                if (response.data.success) {
                    logEvent(analytics, 'post_message', {
                        success: true
                    });

                    //setPage(response.data);
                    //setComments(response.data ?? []);
                    //setDisplayName(response.data.user.displayName)
                    setNewBody('')
                    props.onSuccess();
                }
            })
                .catch((reason: AxiosError) => {
                    logEvent(analytics, 'post_message', {
                        success: false,
                        status_code: reason.response!.status
                    });

                    if (reason.response!.status === 401) {
                        localStorage.removeItem("firebaseJWT");
                        state.setFirebaseJWT("");
                    }
                });


            // // ===FIRESTORE RULE===
            // // match /pages/{pageId}/comments/{document=**} {
            // //     allow read: if request.auth != null;
            // //     allow create: if request.auth.uid == request.resource.data.uid;
            // // }
            // const docRef1 = await addDoc(collection(db, `pages/${props.page.id}/comments`), {
            //     uid: user.uid,
            //     name: user.displayName,
            //     body: newBody,
            //     type: props.type,
            //     created: new Date().toISOString(),
            // });

            // ===FIRESTORE RULE===
            // match /users/{userId}/comments/{document=**} {
            //     allow read: if request.auth != null;
            //     allow create: if request.auth.uid == userId;
            // }
            // const docRef2 = await addDoc(collection(db, `users/${user.uid}/comments`), {
            //     pageId: props.page.id,
            //     pageCommentId: docRef1,
            //     pageName: props.page.title,
            //     pageUrl: props.page.url,
            //     body: newBody,
            //     type: props.type,
            //     created: new Date().toISOString(),
            // });

        } catch (e) {
            alert(JSON.stringify(e));
        }
    }

    if (props.type !== 'comment' && props.type !== 'summary') {
        return (
            <>Unsupported comment type</>
        );
    }

    // if (! user?.emailVerified) {
    //     return (
    //         <div className="relative block w-full bg-gray-200 dark:bg-gray-800 bg-opacity-50 border border-dotted dark:border-gray-700 rounded-lg px-12 py-5 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center">
    //             Please verify your email address before posting.
    //         </div>
    //     )
    // }

    return (
        <>

            {
                user &&
                <>
                    <div className="zbg-white zpx-4 zpy-5 zsm:px-6 mt-6">
                        <div className="flex items-start space-x-4">
                            <div className="flex space-x-3">
                                <div className="flex-shrink-0">
                                    {user.photoURL &&
                                        <img
                                            className="h-8 w-8 rounded-full"
                                            src={user.photoURL}
                                            alt=""
                                        />
                                    }

                                    {!user.photoURL &&
                                        <svg className="h-8 w-8 rounded-full text-gray-300" fill="currentColor"
                                             viewBox="0 0 24 24">
                                            <path
                                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                        </svg>
                                    }
                                </div>
                                <div className="min-w-0 flex-1">
                                    <p className="text-lg font-medium text-gray-900 dark:text-gray-100">
                                        {user.displayName ?? 'Anonymous'}
                                    </p>
                                    {/*<p className="text-sm text-gray-500 dark:text-gray-400">*/}
                                    {/*    Post a {props.type}*/}
                                    {/*</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2">
                        <div className="min-w-0 flex-1">

                            <div
                                className="border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                                <label htmlFor="comment" className="sr-only">Add your {props.type}</label>
                                <textarea name="body" id="body"
                                          className="block w-full h-40 px-2 py-2 border-0 resize-none focus:ring-0 sm:text-sm dark:bg-black dark:text-slate-300"
                                          placeholder={'Post your ' + props.type + '...'}
                                          onChange={(e) => setNewBody(e.target.value)}
                                          value={newBody}></textarea>

                                <div className="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
                                    <div className="flex">
                                        <div className="h-9 text-left ml-1">

                                            {/*<label htmlFor="location" className="inline float-left text-sm font-medium text-gray-700 mt-2 mr-2">*/}
                                            {/*    Language*/}
                                            {/*</label>*/}
                                            <select
                                                id="location"
                                                name="location"
                                                className="block w-full pl-3 pr-10 py-2 text-md border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-slate-800 dark:text-slate-200"
                                                // defaultValue="en"
                                                onChange={(e) => setLang(e.target.value)}
                                                value={lang}
                                            >
                                                {languages.map((language, i) => (
                                                    <option key={i} value={language[1]}>{language[0]}</option>
                                                ))}

                                            </select>

                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="h-9 text-right mr-1">
                                            <button onClick={PostMessage} type="submit"
                                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Post
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            }


            {
                !user &&
                <>
                    {/*<Link href={`/login`}>*/}
                    {/*    <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg px-12 py-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center">*/}
                    <div className="relative block w-full bg-gray-200 dark:bg-gray-800 bg-opacity-50 border border-dotted dark:border-gray-700 rounded-lg px-12 py-5 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center">
                        Please login to post a {props.type}.
                    </div>
                    {/*</Link>*/}
                </>
            }


        </>
    )
}

export default PostMessageBox;
